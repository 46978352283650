import { AbstractModel } from "../abstract-model";
import { MarketTruckProductsModel } from "./market-truck-products.model";
import { TruckPackModel } from "./truck-pack-model";
import {MinDTO} from "../min-dto";

export class TruckModel extends AbstractModel {

    operationType: string;
    // TOTAL / DIMENSION
    uselbInch: boolean = false;
    // total / dimessions
    td: string = 'T';

    kp: string;
    // PACK
    totalWeight: number = null;
    totalVolume: number = null;
    cwg: number = null;
    pieces: number;
    stackable: boolean = true;
    chargeableWeight: number;
    chargeableVolume: number;
    chargeableLdm: number;
    totalLoadingMeter: number;
    totalLdm: number;
    truckBodyType: string;

    // PACKS
    packs: TruckPackModel[] = [];

    calculatedPieces: number;

    // PRODUCT
    products: MarketTruckProductsModel[] = [];
    selectedProduct: MarketTruckProductsModel;

}
