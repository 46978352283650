import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import { ZipModel } from "src/app/models/zip-model";

@Injectable()
export class ZipService extends BaseService<any>{
  constructor(public httpClient: HttpClient) {
    super(
      'zip',
      httpClient);
  }
  autocomplete = this.search;

  search(params): Observable<ZipModel[]> {
    let uri = new URL(environment.baseUrl + `/zip`).updateQueryParam(params);
    return this.httpClient.get<ZipModel[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
      .pipe(
        tap(),
        catchError(this.handleError)
      )
  }

    searchByText(params): Observable<ZipModel[]> {
        console.log(params)
        let uri = new URL(environment.baseUrl + `/zip/searchByText`).updateQueryParam(params);
        return this.httpClient.get<ZipModel[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

}
