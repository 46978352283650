import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { SelectBoxItemModel } from '../models/select-box-item.model';
import { environment } from 'src/environments/environment';
import { ChangeLanguageService } from './change-language.service';

@Injectable()
export class SelectBoxItemService extends BaseService<any>{
  
  constructor(public httpClient: HttpClient, private languageService: ChangeLanguageService) {
      super('authentication', httpClient);
  }

  getSelectBoxItems(name: string):  Observable<SelectBoxItemModel[]> {
        
    let uri = new URL(environment.baseUrl + `/selectBox/items`).updateQueryParam({name, lang: this.languageService.getCurrentLanguage()});
    
    return this.httpClient.get<SelectBoxItemModel[]>(uri.toString(),  { withCredentials: false, headers:  this.httpHeaders() })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
  }

  async getItemsAndSaveLocalStorage  (name: string) {
    let selectBoxItems: SelectBoxItemModel[] = []; 
  
    if(!localStorage.getItem(name)) {
  
      selectBoxItems = await this.getSelectBoxItems(name).toPromise();
      
      if(selectBoxItems && selectBoxItems.length > 0) {
        localStorage.setItem(name, JSON.stringify(selectBoxItems));
      }
  
    } else {
      selectBoxItems = JSON.parse(localStorage.getItem(name));
    } 

    return selectBoxItems;
  }

}
