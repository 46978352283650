import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordModel } from 'src/app/models/forgot-password.model';
import { AuthenticationService } from 'src/app/services/user/authentication.service';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';


@Component({
    selector: 'app-forgot',
    templateUrl: './forgot-password.component.html',
    styleUrls:['./forgot-password.component.scss']
})

export class ForgotPasswordComponent extends BaseFormComponentComponent implements OnInit {


    model: ForgotPasswordModel = new ForgotPasswordModel();
    hide: boolean = true;
    hideReply: boolean = true;

    constructor(protected injector: Injector,  private authService: AuthenticationService, private activatedRoute: ActivatedRoute, private router: Router) {
        super(injector);
    }

    ngOnInit(): void {
        this.model.token = this.activatedRoute.snapshot.params.code;
    }

    async reset() {
       
        if(!(await this.isValid())) return;

        this.isLoading = true;
        try {

             await this.authService.reset(this.model).toPromise();

            this.router.navigate(['/home']);

            setTimeout(() => {
                this.showMessage('success', "resetPasswordSuccess", null, 5000);
            }, (1000));

            this.isLoading = false;
        } catch {
            this.isLoading = false;
        }

    }

}
