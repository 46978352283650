import { Component, OnInit, Input } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AirModel } from './models/air/air-model';
import { StepperService } from './stepper.service';
import { SeaModel } from './models/sea/sea-model';
import {TruckModel} from "./models/truck/truck-model";

@Component({
  selector: 'x-rightpanel',
  templateUrl: './app.rightpanel.component.html',
})
export class AppRightpanelComponent implements OnInit {

  stickyPanel: HTMLElement;
  initialOffsetTop: number;
  isEmpty: true;

  @Input("model") model: any = null;
  @Input("modelType") modelType: string = null;
  @Input("displayOptions") displayOptions: any = {showInfo: true,showLocation: true,showCargo: true, isConfirmation : false, showOnlyComponent: false} ;

  constructor(public appMain: AppMainComponent, private stepService: StepperService) {
    setTimeout(() => {
      this.initialOffsetTop = document.querySelector<HTMLElement>(".header-img")?.offsetHeight -20
      if(this.stickyPanel) {
        this.stickyPanel.style.top = `${(this.initialOffsetTop + 110)}px`
      }

    }, 500)


  }

  ngOnInit(): void {
    this.stickyPanel = document.querySelector('.sticky-panel');
    window.addEventListener('scroll', this.handleScroll.bind(this));

    switch (true) {
      case this.model instanceof AirModel:
          this.modelType = 'AIR';
          return;
      case this.model instanceof SeaModel:
          this.modelType = 'SEA';
          return;
        case this.model instanceof TruckModel:
            this.modelType = 'TRUCK';
            return;
    }

    if(this.model instanceof AirModel) this.modelType = 'AIR';
    if(this.model.operationType == 'LCL' || this.model.operationType == 'FCL' ) this.modelType = 'SEA';
  }


  handleScroll() {
    if(this.stickyPanel) {
      if (window.pageYOffset > (this.initialOffsetTop -10)) {
        this.stickyPanel.style.top = '105px';
      } else {
        this.stickyPanel.style.top = `${(this.initialOffsetTop + 100)}px`
      }
    }

  }
  formatPrice(price: number): string {
    return price.toLocaleString();
  }
}
