import { Directive, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Injector } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MessageService } from "primeng/api";
import { ConfirmationService } from "primeng/api";
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AppMainComponent } from 'src/app/app.main.component';

@Directive()
export abstract class BaseFormComponentComponent implements OnInit, AfterViewInit {


  @ViewChild(NgForm) form: NgForm;
  @ViewChild('formChild', { static: false }) formDom: ElementRef

  isLoading: boolean = false;
  userLocale: string = "tr-TR";

  translation: TranslateService;
  messageService: MessageService;
  confirmService: ConfirmationService;
  dialogService: DialogService
  appMain: AppMainComponent;



  constructor(protected injector: Injector) {
    this.translation = this.injector.get(TranslateService);
    this.messageService = this.injector.get(MessageService);
    this.confirmService = this.injector.get(ConfirmationService);
    this.dialogService = this.injector.get(DialogService);
    this.appMain = this.injector.get(AppMainComponent);
  }

  toggleLoadingProgress() {
    this.appMain.loadingProgress = !this.appMain.loadingProgress;
  }

  showMessage(severity: string, detail: string, summary: string = null, life = 3000) {
    this.translation.get(detail).subscribe(msg => {
      this.messageService.add({ key: 'html-msg', severity, summary, detail: msg, life: life, sticky: true });
    })
  }
  showMessageWithOutTranslate(severity: string, detail: string, summary: string = null, life = 3000) {
    this.messageService.add({ key: 'html-msg', severity, summary, detail: detail, life: life });
  }
  showMessageStickly(severity: string, detail: string, summary: string = null) {
    this.translation.get(detail).subscribe(msg => {
      this.messageService.add({ key: 'html-msg', severity, summary, detail: msg, sticky: true });
    })

  }
  showMessageTemplate(severity: string, detail: string, summary: string = null, life = 3000) {
    this.translation.get(detail).subscribe(msg => {
      this.messageService.add({ severity, key: 'html-msg', summary, detail: msg, life: life });
    })

  }




  confirm(message: string = null) {
    return new Promise((resolve, reject) => {
      this.confirmService.confirm({
        message: message || 'İşleme devam etmek istediğinize emin misiniz ?',
        accept: () => resolve(true),
        reject: () => reject()
      })
    })

  }

  ngOnInit(): void {
    if (localStorage.get('locale')) this.userLocale = localStorage.getItem('locale');
  }

  ngAfterViewInit(): void {
    /* form içerisindeki tüm inputların autocomplete özelliği kapatılıyor. */
    if (this.formDom && this.formDom.nativeElement) {
      Array.from(this.formDom.nativeElement.querySelectorAll('input')).forEach(s => s['autocomplete'] = 'off')
    }
  }
  async isValid() {
    const invalid = [];

    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name.split("_")[0].trim());
      }
    }

    for (let index = 0; index < this.formDom.nativeElement.getElementsByClassName('required').length; index++) {
      const element = this.formDom.nativeElement.getElementsByClassName('required')[index];
      let currentClass = element.getAttribute('class');
      element.setAttribute('class', currentClass.replace('required', ''));
    }

    if (invalid.length > 0) {

      invalid.forEach((key, idx) => {

        const element = this.formDom.nativeElement.querySelector(`[name=${key}]`);
        if (element) {

          if (element.tagName == 'INPUT') {

            if (idx == 0) element.focus();
            const currentClass = element.getAttribute('class');
            element.setAttribute('class', currentClass + ' required')

          } else {
            let inputElement = element.querySelector("input");

            if (idx == 0) inputElement.focus();

            const currentClass = inputElement.getAttribute('class');
            inputElement.setAttribute('class', currentClass + ' required')
          }



        }
      })
      const invalidFieldTranslatedValues = [];

      for (let indx = 0; indx < invalid.length; indx++) {
        let translatedFieldLabel = await this.translation.get(invalid[indx]).toPromise();
        invalidFieldTranslatedValues.push(String(translatedFieldLabel).toUpperCase());

      }


      const formInvalidMessage = await this.translation.get('formInvalid').toPromise();

      let requiredFieldText = "";
      if (invalidFieldTranslatedValues.length > 0) requiredFieldText = invalidFieldTranslatedValues.join("<br/>");

      this.injector.get(MessageService).add({ severity: 'warn', key: 'html-msg', summary: '', detail: `${formInvalidMessage} </br>  ${requiredFieldText}` });
    }
    return invalid.length == 0
  }

  addQueryParam(key, value) {
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set(key, value);
    // history.replaceState(null, null, location.href + "?" + decodeURIComponent(queryParams.toString()));
    history.replaceState(null, null, location.pathname + "?" + decodeURIComponent(queryParams.toString()));
  }
}
