import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { SelectBoxAccessor } from "./select-box-accessor";
import { SelectBoxItemService } from 'src/app/services/select-box-item.service';
import { SelectBoxItemModel } from 'src/app/models/select-box-item.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
  providers: [SelectBoxAccessor]
})

export class SelectBoxComponent implements OnInit, ControlValueAccessor {

  @Input() selectBoxName: string;
  @Input() placeholder: string = "";
  @Input() required: boolean = false;
  @Input() optionValue:string = "code";
  @Input() optionLabel:string = "name"
  @Input() id:string = null
  @Input() showClear: boolean = true;
  @Input() azSort: boolean = false;
  @Input() options: any[]= null;
  @Input() emptyDefault: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  private _disabledOptions: any[];
    
  @Input() set disabledOptions(value: any[]) {
     this._disabledOptions = value;
     this.setDisableOption();
  }
  
  get disabledOptions(): any[] {
      return this._disabledOptions;
  }

  private innerValue: any = null;
  items: SelectBoxItemModel[] = [];
  defaultValue: any = null;

  //by the Control Value Accessor
  private onTouchedCallback: () => void =  () => {};
  private onChangeCallback: (_: any) => void =  () => {};

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }


  constructor(private  changeDetector: ChangeDetectorRef, private selectBoxItemService: SelectBoxItemService, private translateService: TranslateService) {  }


  async ngOnInit() {
    
    this.placeholder = await this.translateService.get(this.placeholder).toPromise();

    if(this.options) {

      if(this.emptyDefault) {
        const defaultItem = new SelectBoxItemModel();
        defaultItem.code = null;
        defaultItem.name = 'Seçiniz';
        this.items = [defaultItem].concat(this.options)
      } else {
        this.items = this.options;
      }

    } else {
      if(this.selectBoxName) {
        let selectBoxItems = localStorage.getItem(this.selectBoxName);
        if(!selectBoxItems) {
          this.items = await this.selectBoxItemService.getSelectBoxItems(this.selectBoxName).toPromise();
          if(this.azSort) this.items =  this.items.sort((a,b) => {return a.name.localeCompare(b.name)});
          localStorage.setItem(this.selectBoxName, JSON.stringify(this.items));
        }
        if(!selectBoxItems) return;
        if(this.azSort) {
          this.items = JSON.parse(selectBoxItems).sort((a,b) => {return a.name.localeCompare(b.name)});
        } else {
          this.items = JSON.parse(selectBoxItems);
        }
        this.setDisableOption();
      }
    }
   
  }

  ngAfterContentChecked(): void  {

    this.changeDetector.detectChanges();

}


  setDisableOption() {
    if(this.disabledOptions && this.disabledOptions.length > 0) {
      this.items.forEach(i => {
        if(this.disabledOptions.find(d => JSON.stringify(d) === JSON.stringify(i) )) i['disabled'] = true;
      })
    }
  }

}
