import { Component, OnInit, Injector } from '@angular/core';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { UserService } from 'src/app/services/user/user.service';
import { UserModel } from 'src/app/models/auth/user.model';
import { AuthenticationService } from 'src/app/services/user/authentication.service';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-admin-dialog',
  templateUrl: './admin-dialog.component.html',
  styleUrls: ['./admin-dialog.component.scss']
})
export class AdminDialogComponent extends BaseFormComponentComponent implements OnInit {

  referenceNo: string;
  foundedUser: UserModel = null;

  constructor(protected injector: Injector, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private userService: UserService, private auth: AuthenticationService, private router: Router) {
     super(injector)
  }

  async searchUserByRefNo(form) {
    
    this.foundedUser = null;

    if(!this.referenceNo) return;

    try {
      this.isLoading = true;
      this.foundedUser = await this.userService.getByReferenceNo(this.referenceNo).toPromise();
      this.isLoading = false;
    } catch ( e ) {
      this.isLoading = false;
    }
    
  }

  loginWithAdmin() {
    this.ref.close();
    this.router.navigate(['/home']);
  }

  async loginWithUser() {
    if(!this.foundedUser) {
      this.showMessage('warn', 'notSelectedUser');
      return;
    }
    
    try {
        this.isLoading = true;
        const auth = await this.auth.login(this.config.data.loginDTO.email, this.config.data.loginDTO.password, this.referenceNo).toPromise();
        let user = auth.user;
        let token = auth.token;
        localStorage.setItem("token", token);
        localStorage.setItem('user', JSON.stringify(user));
        this.isLoading = false;
        this.ref.close();

        this.router.navigate(['/home']);
    } catch(e) {
        this.ref.close();
        this.isLoading = false
    }
 
    
  }
  ngOnInit(): void {
  }

}
