import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepperService {
  private currentStepSource = new BehaviorSubject<number>(1);
  currentStep = this.currentStepSource.asObservable();
  public step: number = 1;

  constructor() { }

  changeCurrentStep(step: number) {
    this.step = step;
    this.currentStepSource.next(step);
    
  } 
}
