import { Component, Injector } from '@angular/core';
import {  DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/services/user/authentication.service';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';

@Component({
  selector: 'app-activation-dialog',
  templateUrl: './activation-dialog.component.html',
  styleUrls: ['./activation-dialog.component.scss']
})
export class ActivationDialogComponent extends BaseFormComponentComponent{

  constructor(protected injector: Injector, public ref: DynamicDialogRef, private authService: AuthenticationService) {
    super(injector);
  }


  async reSendCode() {

    try {
      this.isLoading = true;
      await this.authService.sendVerification().toPromise();
      this.isLoading = false;
      this.login();

      this.showMessage('success', 'forgotEmailSended')
    } catch {
      this.isLoading = false;
    }    
  }
  
  login() {
    this.appMain.onRightPanelClick(null);
    this.ref.close();
  }

  closeDialog() {
    this.ref.close();
  }

}
