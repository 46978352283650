import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import { MinDTO } from "src/app/models/min-dto";

@Injectable()
export class TaxOfficeService extends BaseService<any>{
  constructor(public httpClient: HttpClient) {
    super(
      'taxOffice',
      httpClient);
  }
  autocomplete = this.search;

  search(name: string): Observable<MinDTO[]> {
    let uri = new URL(environment.baseUrl + `/public/taxOffice`).updateQueryParam({name});
    return this.httpClient.get<MinDTO[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
      .pipe(
        tap(),
        catchError(this.handleError)
      )
  }

}
