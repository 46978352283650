import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';


@Injectable()
export class FixedPriceService extends BaseService<any>{
    constructor(public httpClient: HttpClient) {
        super(
            'fixedSearch',
            httpClient);
    }


    searchFixedPrices(params): Observable<any[]> {
        let uri = new URL(environment.baseUrl + `/public/fixedSearch/byCountryCode`).updateQueryParam(params);

        return this.httpClient.get<any[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    searchPricesByCode(params): Observable<any[]> {
        let uri = new URL(environment.baseUrl + `/public/fixedSearch/searchByCountryCode`).updateQueryParam(params);

        return this.httpClient.get<any[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    searchDashboardPricesByCode(): Observable<any[]> {
        let uri = new URL(environment.baseUrl + `/dashboardFixedPrice`);

        return this.httpClient.get<any>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getIpLocation(isSecure: boolean = false): Promise<any> {

       const url=`${isSecure ? 'https' : 'http'}://pro.ip-api.com/json/?fields=61439&key=hDHWeK8IbTjWdwH`;

       return new Promise((resolve, reject) => {
            return fetch(url)
            .then((response) => response.json())
            .then((jsonResponse)  =>  resolve(jsonResponse))
            .catch((e) => reject(e))
        })

    }


}
