import { Component, OnInit, Injector, Inject } from '@angular/core';
import { StepperService } from 'src/app/stepper.service';
import { AirComponent } from '../air.component';
import { AirSearchService } from 'src/app/services/air/air-search.service';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { AirPriceDetailDialogComponent } from 'src/app/components/air-price-detail-dialog/air-price-detail-dialog.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import {Router} from "@angular/router";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class AirConfirmationComponent  extends BaseFormComponentComponent implements OnInit {

  currentStep: number;
  priceInfoDialogRef: DynamicDialogRef;

  constructor(
    protected injector: Injector, @Inject(AirComponent) private parent: AirComponent, private stepperService: StepperService, private airSearchService: AirSearchService, private router: Router,) {
    super(injector)
    this.parent.isLoading = true;

  }
  async showAirPriceInfo() {
    this.priceInfoDialogRef = this.dialogService.open(AirPriceDetailDialogComponent, { header: null, showHeader: false, modal:true,  width: '800px', height: 'auto', closable: false,  data: {product: this.parent.model.selectedProduct, model: this.parent.model}});
  }
  ngOnInit(): void { }

    goList() {
        this.router.navigate(['/home/air/list/reservation'], { queryParams: { operation: 'air'} });
    }

}
