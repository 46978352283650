import { Component } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TruckModel } from 'src/app/models/truck/truck-model';
import { MarketTruckProductsModel } from 'src/app/models/truck/market-truck-products.model';
import { SurcharDetailDialog } from '../surcharge-detail-dialog/surcharge-detail-dialog.component';
import { TruckCalculatedChargesModel } from 'src/app/models/truck/truck-calculated-charges.model';

@Component({
  selector: 'app-truck-price-detail-dialog',
  templateUrl: './truck-price-detail-dialog.component.html',
  styleUrls: ['./truck-price-detail-dialog.component.scss']
})
export class TruckPriceDetailDialogComponent {

  product: MarketTruckProductsModel = null;
  model: TruckModel = null;
  surchargeDialogRef: DynamicDialogRef;

  constructor(public config: DynamicDialogConfig, private dialogService: DialogService, public ref: DynamicDialogRef) {
    this.product = this.config.data.product;
    this.model = this.config.data.model;

    console.log(this.model, this.product)

  }


  async showSurchages(surcharge: TruckCalculatedChargesModel) {
    this.surchargeDialogRef = this.dialogService.open(SurcharDetailDialog, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { surcharge: surcharge } });
  }


  closeDialog() {
    this.ref.close();
  }
}
