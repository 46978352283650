import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SearchDetailDialog } from 'src/app/components/search-detail-dialog/search-detail-dialog.component';
import { NotificationsModel } from 'src/app/models/notifications.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import {Subject} from "rxjs";
import { Router } from '@angular/router';

export const readNotify = new Subject<number>();

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit {


  notifications = new NotificationsModel();
  isLoading: boolean = false;
  notificationsList: NotificationsModel[] = [];
  searchDetailDialogRef: DynamicDialogRef;
  element: HTMLElement
  lazyOffset: number = 0;
  loading: boolean = true;
  isNewNotificationSelected: boolean = true;


  constructor(public ref: DynamicDialogRef, public notificationsService : NotificationsService, private router: Router, private dialogService: DialogService ) {
    this.element = document.getElementsByClassName('p-dialog-content')[0] as HTMLElement;
   }

  @Input() display: boolean = false;
  ngOnInit(): void {

    this.getWithOffsetAndReadTime();

    this.element.addEventListener("scrollend", (event) => {
      if(( Math.ceil(this.element.scrollHeight - this.element.offsetHeight)) <= Math.ceil(this.element.scrollTop) + 5) {
        this.getWithOffsetAndReadTime()
      }
    });

  }

  closeDialog() {
    this.ref.close();
  }

  async getWithOffsetAndReadTime() {
    try {
      this.loading = true;
      /* Notification okundu olarak işaretlemeyi marketplace-backend de gerçekleştiriyor. okunanlar bildirimler için offset değerine ihtiyaç bulunuyor. number olarak 0 gönderildiğinde httpClient parameteyi göndermiyor o nedenle string e çeviriyoruz. */

      const readedNotification = await  this.notificationsService.getWithOffsetAndReadTime(this.isNewNotificationSelected, this.isNewNotificationSelected ? "0" : String(this.lazyOffset), this.notificationsList.map(s => s.id)).toPromise();


      this.notificationsList  = [...this.notificationsList, ...readedNotification];
      this.lazyOffset = this.lazyOffset + 10


      this.loading = false;
    } catch {
      this.loading = false;
    }
  }

  async showNotification(model: NotificationsModel ) {

    await this.notificationsService.readNotifications([model.id]).toPromise();
    if(model.relatedType == 'US') {
      this.ref.close();
      this.router.navigate(['/home/profile']);
      readNotify.next(1);
      return;
    }
    this.searchDetailDialogRef = this.dialogService.open(SearchDetailDialog,{ header: null, showHeader: false, modal: true, width: '90vw', height:'100vh', closable: false, data: {operation: model.operationType, id: model.relatedId} });

    // Okunan bildirimleri düşümek için...
    readNotify.next(1);



  }

  onChangeTab(event: any) {
    this.isNewNotificationSelected = event.index == 0;
    this.lazyOffset = 0;
    this.notificationsList = [];
    this.getWithOffsetAndReadTime()
  }
}
