import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { AirModel } from 'src/app/models/air/air-model';
import {Observable} from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MinDTO } from 'src/app/models/min-dto';
import { DashboardListResultCount } from 'src/app/models/air/list/dashboard-list-result-counts';
import { MarketAirProductsModel } from 'src/app/models/air/market-air-products.model';

@Injectable({
  providedIn: 'root'
})
export class AirSearchService  extends BaseService<any>{

  constructor(public httpClient: HttpClient) {
      super(
          'air',
          httpClient);
  }
  
  search(airModel: AirModel): Observable<AirModel> {
    let uri = new URL(environment.baseUrl + `/air/search`);
    
    return this.httpClient.post<AirModel>(uri.toString(), airModel, { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

  get(marketOfferRequestsId: number): Observable<AirModel> {
    let uri = new URL(environment.baseUrl + `/air`).updateQueryParam({ id: marketOfferRequestsId });
    
    return this.httpClient.get<AirModel>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

  segment(fromAirportId: number, toAirportId: number): Observable<MinDTO> {
    let uri = new URL(environment.baseUrl + `/air/segment`).updateQueryParam({ fromAirportId, toAirportId });
    
    return this.httpClient.get<MinDTO>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }
  
  offer(airModel): Observable<AirModel> {
    let uri = new URL(environment.baseUrl + `/air/offer`);
    
    return this.httpClient.post<AirModel>(uri.toString(), airModel, { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

    
  due(product: MarketAirProductsModel, due: number, ref: string, id: number): Observable<MarketAirProductsModel> {
    let uri = new URL(environment.baseUrl + `/air/due`).updateQueryParam({ due, ref, id });
    
    return this.httpClient.post<MarketAirProductsModel>(uri.toString(), product, { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

  shipment(airModel): Observable<AirModel> {
    let uri = new URL(environment.baseUrl + `/air/shipment`);
    
    return this.httpClient.post<AirModel>(uri.toString(), airModel, { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }
  insurance(airModel): Observable<AirModel> {
    let uri = new URL(environment.baseUrl + `/air/insurance`);
    
    return this.httpClient.post<AirModel>(uri.toString(), airModel, { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }


}