import { MinDTO } from "../min-dto";

export class CompanySearchResultModel {
id: number;
type: string;
name: string;
address: string;
city: MinDTO
country: MinDTO
phone: string;
email: string;
}