export class TruckPackModel {
    lenght: number;
    width: number;
    height: number;
    perKg: number;
    lineVolume: number;
    pieces: number;
    packing: string;
    totalWeight: number;
    totalLoadMeter: number;
    perLoadmeter: number;
}
