import { AbstractEntity } from "./abstract-entity.model";

export class NotificationsModel extends AbstractEntity{
    id: number;
    relatedType: string;
    relatedId: number;
    description: string;
    title: string;
    readTime: Date;
    relatedNo: string;
    operationType: string;
}
