import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-customers-review-carousel',
  templateUrl: './customers-review-carousel.component.html',
  styleUrls: ['./customers-review-carousel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomersReviewCarouselComponent {
    reviews: any[] = [1,2,3,4,5,6,7];

}
