import { Component, OnInit, Output, Input, EventEmitter, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CountDownComponent implements OnInit {

  @Output() onTimeEnd: EventEmitter<any> = new EventEmitter();

  @Input() tooltip: string = null
  @Input() minute: number = 2;
  @Input() second: number = 59;

  countDownTxt: string;



  constructor() {  }

  async ngOnInit() {
    this.countDown()
  }


    countdownString(minutes, seconds) {
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

  countDown() {
      let remainingSeconds = this.minute * this.second;
      const interval = setInterval(() => {
          const currentMinutes = Math.floor(remainingSeconds / 60);
          const currentSeconds = remainingSeconds % 60;
          this.countDownTxt = this.countdownString(currentMinutes, currentSeconds);
          if (remainingSeconds <= 0) {
              this.onTimeEnd.emit();
              clearInterval(interval);
          }
          remainingSeconds--;
      }, 1000);

  }


}
