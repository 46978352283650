import { Component, OnInit, Input, Inject, Injector, OnDestroy } from '@angular/core';
import { StepperService } from 'src/app/stepper.service';
import { TruckModel } from 'src/app/models/truck/truck-model';
import { TruckComponent } from '../truck.component';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { TruckSearchService } from 'src/app/services/truck/truck-search.service';
import { MarketTruckProductsModel } from 'src/app/models/truck/market-truck-products.model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TruckPriceDetailDialogComponent } from 'src/app/components/truck-price-detail-dialog/truck-price-detail-dialog.component';

@Component({
  selector: 'app-price-request',
  templateUrl: './price-request.component.html',
  styleUrls: ['./price-request.component.scss']
})
export class TruckPriceRequestComponent extends BaseFormComponentComponent implements OnInit, OnDestroy {

  @Input("model") model: TruckModel;
  adminDialogRef: DynamicDialogRef;

  constructor(
    protected injector: Injector, @Inject(TruckComponent) public parent: TruckComponent, private stepperService: StepperService, private truckSearchService: TruckSearchService) {
    super(injector)
    this.parent.isLoading = true;

  }

  async ngOnInit() {
    if (this.model.selectedProduct && this.parent.showOnlyComponent) {
      this.model.selectedProduct.isSelectedFromList = true;
      this.model.products = [this.model.selectedProduct].concat(this.model.products).filter(s => s);
    }
  }
  ngOnDestroy() {
    if (this.adminDialogRef) {
      this.adminDialogRef.close();
    }
  }

  async makeReservation(selectedProduct: MarketTruckProductsModel = null) {



    this.model.selectedProduct = selectedProduct;

    try {
      this.isLoading = true;
      if (!this.model.selectedProduct || !this.model.selectedProduct.isSelectedFromList) {
        this.model = await this.truckSearchService.offer(this.model).toPromise();
      } else {
        this.model = await this.truckSearchService.insurance(this.model).toPromise();
      }
      this.isLoading = false;
      this.parent.reservationInfoLoading = true;
      window.scroll({ top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth' });
      this.parent.container.clear();
      this.parent.model = this.model;

      this.stepperService.changeCurrentStep(3);
      this.parent.renderStepComponent(this.parent.stepComponents[3], this.model, 3);

    } catch (error) {
      this.model.selectedProduct = null;
      // zaman aşımına uğrayan bir istek gerçekleşti ise bir önceki adıma geri dönüyoruz.
      if (error == 'searchTimeOut') {
        this.parent.container.clear();
        this.stepperService.changeCurrentStep(1);
        window.scroll({ top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth' });
      }

      this.isLoading = false;
      this.parent.reservationInfoLoading = false;
      this.parent.isLoading = false;
    }


  }

  async priceInfo(product: MarketTruckProductsModel) {
    this.adminDialogRef = this.dialogService.open(TruckPriceDetailDialogComponent, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { product: product, model: this.model } });
  }

  onTimeEndAllocatedProduct(product: MarketTruckProductsModel) {
    this.model.selectedProduct = null;
    this.parent.container.clear();
    this.stepperService.changeCurrentStep(1);
    this.showMessage('warn', 'searchTimeOut')
    window.scroll({ top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth' });
  }


}
