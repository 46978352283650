import { Component, OnInit, Output, Input, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocViewerDialogComponent, docAcceptSub } from '../doc-viewer-dialog/doc-viewer-dialog.component';


@Component({
  selector: 'app-doc-viewer-btn',
  templateUrl: './doc-viewer-btn.component.html',
  styleUrls: ['./doc-viewer-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DocViewerBtnComponent implements OnInit, OnDestroy {

  @Output() onAccept: EventEmitter<any> = new EventEmitter();
  @Input() filePath: string = null;
  @Input() isShowOnlyKvkk: boolean = false;
  @Input() label: string = null;
  @Input() showButton: boolean = true;
  labelName: string  = null;


  dialog: DynamicDialogRef;

  constructor(private dialogService: DialogService, private translateService: TranslateService) {  }

  async ngOnInit() {
    this.labelName = this.label;

    this.label = await this.translateService.get(this.label).toPromise();

    docAcceptSub.subscribe(r => this.onAccept.emit(r));
  }

  ngOnDestroy(): void {
    //  docAcceptSub.unsubscribe();
  }


  showDialog() {
    this.dialog = this.dialogService.open(DocViewerDialogComponent, { header: "view", showHeader: false, modal:true, closable: true,  width: '50%', height: '100%',  data: {filePath: this.filePath, name: this.labelName , showButton: this.showButton}});
  }


}
