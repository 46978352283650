import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AirCalculatedChargesModel } from 'src/app/models/air/air-calculated-charges.model';
import { ChangeLanguageService } from 'src/app/services/change-language.service';

@Component({
  selector: 'app-surcharge-detail-dialog',
  templateUrl: './surcharge-detail-dialog.component.html',
  styleUrls: ['./surcharge-detail-dialog.component.scss']
})
export class SurcharDetailDialog implements OnInit{

  surcharge: AirCalculatedChargesModel = null;
  lang: string = "tr";

  @ViewChild("dialogContent", { read: ViewContainerRef }) dialogContent;
  priceInfoDialogRef: DynamicDialogRef;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private languageService: ChangeLanguageService) {
    this.surcharge  = config.data.surcharge;
    this.lang =  this.languageService.getCurrentLanguage();
  }

  async ngOnInit() {
 
  }




  closeDialog() {
    this.ref.close();
  }



}
