import { Component, OnInit, Input} from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { MessageService } from 'primeng/api';
import { ChangePasswordDTO } from 'src/app/models/auth/change-password-dto.model';
import { AuthenticationService } from 'src/app/services/user/authentication.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePassword = new ChangePasswordDTO();
  isLoading: boolean = false;
  constructor(private userService: UserService, private  authService: AuthenticationService, private messageService: MessageService) { }

  @Input() display: boolean = false;
  ngOnInit(): void {
  }

  onChangePassword() {
    /*
      çeviri eklenecek baseform dan extend edilecek.
    */

    if(this.changePassword.oldPassword == this.changePassword.password) {
      this.messageService.add({severity: 'warn', summary: 'Uyarı', detail: 'Mevcut şifre ile yeni şifreniz aynı olamaz!' });
      return;
    }

    if(!this.changePassword.oldPassword || !this.changePassword.password || !this.changePassword.passwordReply) {
      this.messageService.add({severity: 'warn', summary: 'Uyarı', detail: 'Tüm alanları doldurmalısınız!' });
      return;
    }
    if(this.changePassword.password != this.changePassword.passwordReply) {
      this.messageService.add({severity: 'warn', summary: 'Uyarı', detail: 'Şifre tekrarı hatalı!' });
      return;
    }
    this.isLoading  = !this.isLoading;
    this.authService.changePassword(this.changePassword).subscribe(r => {
      this.isLoading  = !this.isLoading;
      this.messageService.add({severity: 'success', summary: 'Başarılı', detail: 'Şifreniz Güncellendi.' });
      this.changePassword.oldPassword = null;
      this.changePassword.password = null;
      this.changePassword.passwordReply = null;
    }, (err) => {
      this.isLoading  = !this.isLoading;
    })
  }


}
