import {Component, OnInit, Input, Inject, Injector, OnDestroy} from '@angular/core';
import { StepperService } from 'src/app/stepper.service';

import { SeaComponent } from '../sea.component';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { SeaSearchService } from 'src/app/services/sea/sea-search.service';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import {
    SeaPriceDetailDialogComponent
} from "../../../components/sea-price-detail-dialog/sea-price-detail-dialog.component";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {
    TariffNotFoundDialogComponent
} from "../../../components/tariff-not-found-dialog/tariff-not-found-dialog.component";

@Component({
  selector: 'app-price-request',
  templateUrl: './price-request.component.html',
  styleUrls: ['./price-request.component.scss']
})
export class SeaPriceRequestComponent extends BaseFormComponentComponent implements OnInit, OnDestroy {

  @Input("model") model: SeaModel;
    adminDialogRef: DynamicDialogRef;

  constructor(
    protected injector: Injector, @Inject(SeaComponent) public parent: SeaComponent, private stepperService: StepperService, private seaSearchService: SeaSearchService) {
    super(injector)
    this.parent.isLoading = true;

  }

  ngOnInit(): void {
      this.parent.isConfirmation = false;
      if(this.model.selectedProduct && this.parent.showOnlyComponent) {
      this.model.selectedProduct.isSelectedFromList = true;
      if (this.model?.products){
          this.model.products = [this.model.selectedProduct].concat(this.model.products).filter(product => {
              return (!product?.isOverLimit && !product?.freights?.errorCode) || product?.isSelectedFromList;
          });
          this.model.products = this.model.products.filter((x, i, a) => a.indexOf(x) == i);
      }else {
          this.model.products = [];
          this.model.products = [this.model.selectedProduct].concat(this.model.products).filter(product => {
              return (!product?.isOverLimit && !product?.freights?.errorCode) || product?.isSelectedFromList;
          });
          this.model.products = this.model.products.filter((x, i, a) => a.indexOf(x) == i);
      }
    }

    this.parent.stepper.notShowReservation = this.onlyShowPriceRequests();

   }

   async makeReservation(selectedProduct: MarketSeaProductsModel = null, marketProductsDefId = null, productId = null) {

    if(selectedProduct) this.model.marketProductsDefId = selectedProduct.marketProductsDefId;
    if(!selectedProduct) this.model.marketProductsDefId = marketProductsDefId;
    this.model.selectedProduct = selectedProduct;

    if (!selectedProduct && marketProductsDefId){
        this.model.newPriceProduct = productId;
    }

    try {
      if(!this.model.selectedProduct ||  !this.model.selectedProduct.isSelectedFromList) {
       this.model = await this.seaSearchService.offer(this.model).toPromise();
      } else {
        this.model = await  this.seaSearchService.insurance(this.model).toPromise();
      }

        this.isLoading = false;
        this.parent.reservationInfoLoading = true;
        window.scroll({top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth'});
        this.parent.container.clear();
        this.parent.model = this.model;

        this.stepperService.changeCurrentStep(3);
        this.parent.renderStepComponent(this.parent.stepComponents[3], this.model, 3);

        if(!this.parent.showOnlyComponent) {
          this.addQueryParam("page", "REZ")
        }

    } catch(error) {
        this.model.selectedProduct = null;
        // zaman aşımına uğrayan bir istek gerçekleşti ise bir önceki adıma geri dönüyoruz.
        if (error == 'searchTimeOut') {
            this.parent.container.clear();
            this.stepperService.changeCurrentStep(1);
            window.scroll({ top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth' });
        }

        this.isLoading = false;
        this.parent.reservationInfoLoading = false;
        this.parent.isLoading = false;
    }


  }

    async priceInfo(product: MarketSeaProductsModel) {
        this.adminDialogRef = this.dialogService.open(SeaPriceDetailDialogComponent, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { product: product, model: this.model, parentComponent: this } });
    }

    ngOnDestroy() {
        if (this.adminDialogRef) {
            this.adminDialogRef.close();
        }
    }

    calculateTeu() {

       return  this.model?.containerType?.name.startsWith('2') ? this.model?.containerCount : this.model?.containerCount * 2;
    }

    isPrice(){
      return document.getElementById('price_newprice_btn') !== null;
    }

    frontTariffNotFound(){
        this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "front"}});
    }
    endTariffNotFound(){
        this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, {  header: null,  showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "end"}});
    }
    frontEndTariffNotFound(){
        this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, {  header: null,  showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "frontend"}});
    }
    onlyShowPriceRequests() {
        if(this.model.isNoPrice) return false;
        if(!this.model.products) return true;


        const noPriceProductsSize = this.model.products.filter(product => ((product?.isOverLimit && !product?.isSelectedFromList) || product?.freights?.errorCode))

        if(noPriceProductsSize.length == this.model.products.length) return true;

        return false;

      }
}
