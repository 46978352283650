import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {TruckModel} from 'src/app/models/truck/truck-model';
import {Observable} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {MinDTO} from 'src/app/models/min-dto';

@Injectable({
    providedIn: 'root'
})
export class TruckSearchService extends BaseService<any> {

    constructor(public httpClient: HttpClient) {
        super('truck', httpClient);
    }

    search(truckModel: TruckModel): Observable<TruckModel> {
        const uri = new URL(environment.baseUrl + `/truck/search`);
        return this.httpClient.post<TruckModel>(uri.toString(), truckModel, {
            withCredentials: false,
            headers: this.httpHeaders()
        }).pipe(
            tap(),
            catchError(this.handleError)
        );
    }

    get(marketOfferRequestsId: number): Observable<TruckModel> {
        const uri = new URL(environment.baseUrl + `/truck`).updateQueryParam({id: marketOfferRequestsId});

        return this.httpClient.get<TruckModel>(uri.toString(), {withCredentials: false, headers: this.httpHeaders()})
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    segment(fromTruckportId: number, toTruckportId: number): Observable<MinDTO> {
        const uri = new URL(environment.baseUrl + `/truck/segment`).updateQueryParam({fromTruckportId, toTruckportId});

        return this.httpClient.get<MinDTO>(uri.toString(), {withCredentials: false, headers: this.httpHeaders()})
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    offer(truckModel): Observable<TruckModel> {
        const uri = new URL(environment.baseUrl + `/truck/offer`);
        return this.httpClient.post<TruckModel>(uri.toString(), truckModel, {
            withCredentials: false,
            headers: this.httpHeaders()
        }).pipe(
            tap(),
            catchError(this.handleError)
        );
    }

    shipment(truckModel): Observable<TruckModel> {
        const uri = new URL(environment.baseUrl + `/truck/shipment`);
        return this.httpClient.post<TruckModel>(uri.toString(), truckModel, {
            withCredentials: false,
            headers: this.httpHeaders()
        }).pipe(
            tap(),
            catchError(this.handleError)
        );
    }

    insurance(truckModel): Observable<TruckModel> {
        const uri = new URL(environment.baseUrl + `/truck/insurance`);
        return this.httpClient.post<TruckModel>(uri.toString(), truckModel, {
            withCredentials: false,
            headers: this.httpHeaders()
        }).pipe(
            tap(),
            catchError(this.handleError)
        );
    }

    truckBodyTypes(): Observable<MinDTO[]> {
        let uri = new URL(environment.baseUrl + `/truck/truckBodyTypes`)

        return this.httpClient.get<MinDTO[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
}
