import { Component, Input, OnInit } from '@angular/core';
import { StepperService } from 'src/app/stepper.service';


@Component({
  selector: 'x-p-steps',
  templateUrl: './p-steps.component.html',
  styleUrls: ['./p-steps.component.scss']
})
export class PStepsComponent implements OnInit {

  currentStep: number;
  @Input("model") model: any;
  @Input("component") component: any;
  @Input("notShowReservation") notShowReservation: boolean = false;

  constructor(private stepperService: StepperService ) {   }

  ngOnInit(): void {
    this.stepperService.currentStep.subscribe(step => this.currentStep = step);
  }

  goToStep(step: number) {
    if(step > this.currentStep) return;
    if(this.currentStep > 2) return;

    this.currentStep = step;
    this.stepperService.changeCurrentStep(step);

  }
  showLastStep() {
    if(this.currentStep == 3 && (!this.component.model.selectedProduct || this.component.model.offerSubStatus == 'PO')) return false;
    return true;
  }

}
