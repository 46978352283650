import {Component, OnInit} from '@angular/core';
import {FixedPriceService} from "../../services/fixedPrice/fixed-price.service";

@Component({
  selector: 'app-dashboard-fixed-prices',
  templateUrl: './dashboard-fixed-prices.component.html',
  styleUrls: ['./dashboard-fixed-prices.component.scss']
})
export class DashboardFixedPricesComponent implements OnInit{
    values: any;


    constructor(private fixedPriceService: FixedPriceService) {
    }
    async ngOnInit() {
        this.values = await this.fixedPriceService.searchDashboardPricesByCode().toPromise();
    }

}
