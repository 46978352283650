import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Observable, Subject } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { SearchLogClickHistoryModel } from '../models/search-log-click-history.model';


export const ClickHistorySubject = new Subject<any>();

@Injectable()
export class SearchLogClickHistoryService extends BaseService<any>{
  
  constructor(public httpClient: HttpClient) {
      super('history', httpClient);
  }


  create(dto: SearchLogClickHistoryModel): Observable<any> {
  
    let uri = new URL(environment.baseUrl + `/history`)  
    return this.httpClient.post<any>(uri.toString(), dto, { withCredentials: false, headers: this.httpHeaders() })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
  }

  

}
