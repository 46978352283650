import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'x-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
