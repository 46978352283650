import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FixedPriceService} from "../../services/fixedPrice/fixed-price.service";
import { TranslateService } from '@ngx-translate/core';
import { ChangeLanguageService } from 'src/app/services/change-language.service';
@Component({
  selector: 'app-land-page-prices-carrousel',
  templateUrl: './land-page-prices-carrousel.component.html',
  styleUrls: ['./land-page-prices-carrousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandPagePricesCarrouselComponent implements OnInit{
    public items: any[] = [];
    

    @Output()itemsLength: EventEmitter<number> = new EventEmitter();
    page = 0;
    responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    selectedModel: any;
    isLoad: boolean = true;

    imageList = ['air1.jpg', 'air2.jpg', 'air3.jpg', 'air4.jpg', 'air5.jpg', 'air6.jpg', 'sea1.jpg', 'sea2.jpg', 'sea3.jpg', 'sea4.jpg',  'sea5.jpg', 'sea6.jpg', 'truck1.jpg', 'truck2.jpg', 'truck3.jpg']


    constructor(private fixedPriceService: FixedPriceService, private translation: TranslateService, private languageService: ChangeLanguageService) {
    }

    changePage(forward) {

        const width = screen.width;
        const constant = width >= 1024 ? 4 : width < 1024 && width >= 768 ? 3 : width < 768 && width >= 560 ? 2 : 1;
        if (forward) {
            this.page = this.items.length - constant <= this.page ? 0 : this.page + 1;
        }else {
            this.page = this.page == 0 ? this.items.length - constant : this.page - 1;
        }
    }


    getRandomImageList(operationType){
        let list = [];
        if (operationType === 'TRUCK'){
            list = this.imageList.filter(i => i.includes('truck'));
        }else if (operationType === 'SEA'){
            list = this.imageList.filter(i => i.includes('sea'));
        }else if (operationType === 'AIR'){
            list = this.imageList.filter(i => i.includes('air'));
        }
        return list;
    }
    rastgeleElemanSec<T>(operationType): T | undefined {
        const list = this.getRandomImageList(operationType);
        if (list.length === 0) {
            return undefined; // Liste boşsa undefined döndür
        }
        const rastgeleIndex = Math.floor(Math.random() * list.length);
        return list[rastgeleIndex];
    }

   async ngOnInit() {
        try {
            const ipData = await this.fixedPriceService.getIpLocation(location.href.includes('https'));
            const items = await this.fixedPriceService.searchFixedPrices({code: ipData.countryCode}).toPromise();
            
            if(ipData.countryCode != 'TR') {
                this.translation.use('en');
                localStorage.setItem('lang', 'en');
                localStorage.setItem('locale', 'en-US');
                this.languageService.languageChanged.next('en');

            } else {
                this.translation.use('tr');
                localStorage.setItem('lang', 'tr');
                localStorage.setItem('locale', 'tr-TR');
                this.languageService.languageChanged.next('tr');
            }

            this.items = items;
            this.items.forEach(i => i.image = this.rastgeleElemanSec(i.operationType));
            this.isLoad = false;
            this.itemsLength.emit(items.length);

            // scroll fix
            this.changePage(true)
        } catch {
            this.isLoad = false;
            this.itemsLength.emit(0);
        }

    }

}
