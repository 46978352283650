import { Pipe, PipeTransform } from '@angular/core';
import { SelectBoxItemModel } from 'src/app/models/select-box-item.model';
import { SelectBoxItemService } from 'src/app/services/select-box-item.service';

@Pipe({
  name: 'selectBoxItemName'
})
export class SelectBoxItemPipe implements PipeTransform{
    constructor(public selectBoxItemService: SelectBoxItemService) { }

    async transform(code: string, name: string) {
      let selectBoxItems: SelectBoxItemModel[] = []; 

        if(!localStorage.getItem(name)) {

          selectBoxItems = await this.selectBoxItemService.getSelectBoxItems(name).toPromise();
          
          if(selectBoxItems && selectBoxItems.length > 0) {
            localStorage.setItem(name, JSON.stringify(selectBoxItems));
          }

        } else {
          selectBoxItems = JSON.parse(localStorage.getItem(name));
        } 

        const item = selectBoxItems.find(f => f.code == code);
        if(!item) return code;
        return item.name
    }
}