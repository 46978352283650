import { Component, OnInit, Input, Injector, OnDestroy, Inject } from '@angular/core';
import { StepperService } from 'src/app/stepper.service';
import { AirModel } from 'src/app/models/air/air-model';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { AirComponent } from '../air.component';
import { AirSearchService } from 'src/app/services/air/air-search.service';
import { DocViewerDialogComponent } from 'src/app/components/doc-viewer-dialog/doc-viewer-dialog.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AirPriceDetailDialogComponent } from 'src/app/components/air-price-detail-dialog/air-price-detail-dialog.component';
import {UserModel} from "../../../models/auth/user.model";
import {UserService} from "../../../services/user/user.service";
import {CompanySearchResultModel} from "../../../models/company/company-search-result.model";
import {MinDTO} from "../../../models/min-dto";


@Component({
  selector: 'app-reservation-information',
  templateUrl: './reservation-information.component.html',
  styleUrls: ['./reservation-information.component.scss']
})
export class AirReservationInformationComponent extends BaseFormComponentComponent implements OnInit, OnDestroy{

  @Input("model") model: AirModel;

  currentStep: number;
  isCheckboxSelected: boolean = false;
  disableInsuranceCountry = ['CU','MM','IR','IQ','LB','UA','BY','SY','KR','SO','ZW','LY','AF','YE','SD'];
  shipperText: string = "";
  consigneText: string = "";
  paymentCompanyText: string = "";
  isApproveSelected: boolean = false;
  termsDialog: DynamicDialogRef;
  priceInfoDialogRef: DynamicDialogRef;
  isSelectedShipper = false;
  isSelectedConsignee = false;
  isSelectedPaymentCompany = false;
  paymentCompanyTypes: any = ['CUSTOMER', 'CONSIGNEE', 'SHIPPER', 'OTHER'];
  user: UserModel = new UserModel();

  constructor(protected injector: Injector, private userService: UserService,
     @Inject(AirComponent) private parent: AirComponent, private stepperService: StepperService, private airSearchService: AirSearchService) {
    super(injector);
  }


  async ngOnInit() {
    this.model.paymentCompanyType = 'CUSTOMER';
    this.user = await this.userService.user().toPromise();
    this.model.isUserAgency = this.user?.isAgency;
    this.setCustomer();
    this.calculateAgencyFee();
    if (this.model.selectedDueDate){
        this.model.disablePaymentType = true;
    }
  }

    private setCustomer() {
        this.model.paymentCompanyCountry = new MinDTO();
        this.model.paymentCompanyCountry.name = this.user.country.name;
        this.model.paymentCompanyCountry.id = this.user.country.id;
    }

    ngOnDestroy(): void {
    // CompanySearchResultModel tipine string değer setlendiği için bir önceki steplerde post edildiğinde backend parse hatası veriyor o yüzden siliniyor.
    if(!this.model.shipper?.id) this.model.shipper = null;
    if(!this.model.consignee?.id) this.model.consignee = null;
    if(!this.model.paymentCompany?.id) this.model.paymentCompany = null;
   }
  onSelectShipper() {
    this.isSelectedShipper = true;
    this.model.shipperCountry = null;
    this.model.shipperAddress = null;
    this.model.shipperCity =  null;
    this.model.shipperPhone = null;
    this.model.shipperEmail = null;


    if(this.model.shipper?.id) {
      this.model.shipperCountry =  this.model.shipper?.country;
      this.model.shipperAddress =  this.model.shipper?.address;
      this.model.shipperCity =  this.model.shipper?.city;
      this.model.shipperEmail = this.model.shipper?.email;
      this.model.shipperPhone = this.model.shipper?.phone;
    }
     if (this.model?.paymentCompanyType == 'SHIPPER'){
         this.setPaymentCompany('SHIPPER');
         this.calculateAgencyFee();
     }
  }

  onSelectConsignee(event) {
    this.isSelectedConsignee = true;
    this.model.consigneeCountry = null;
    this.model.consigneeAddress = null;
    this.model.consigneeCity =  null;
    this.model.consigneePhone = null;
    this.model.consigneeEmail = null;

    if(this.model.consignee?.id) {
      this.model.consigneeCountry =  this.model.consignee?.country;
      this.model.consigneeAddress =  this.model.consignee?.address;
      this.model.consigneeCity =  this.model.consignee?.city;
      this.model.consigneeEmail = this.model.consignee?.email;
      this.model.consigneePhone = this.model.consignee?.phone;
    }
      if (this.model?.paymentCompanyType == 'CONSIGNEE') {
          this.setPaymentCompany('CONSIGNEE');
          this.calculateAgencyFee();
      }

  }

   async approve() {

    if(!this.isCheckboxSelected) {
      this.showMessage('warn', 'useConditionRequired');
      return;
    }

    const valid = await this.isValid();
    if (!valid) return;

    let validity = true;
    if(!this.model.shipper?.id) {
      this.model.shipperText = this.shipperText;
      this.model.shipper = null;
    }


    if(!this.model.consignee?.id){
      this.model.consigneeText = this.consigneText;
      this.model.consignee = null;
    }

       if(!this.model.paymentCompany?.id){
           this.model.paymentCompanyText = this.paymentCompanyText;
           this.model.paymentCompany = null;
       }

       const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

       if (!emailRegex.test(this.model.consigneeEmail)){
           this.showMessage('warn', 'wrongEmailAddress');
           return;
       }

       if (!emailRegex.test(this.model.shipperEmail)){
           this.showMessage('warn', 'wrongEmailAddress');
           return;
       }
       if (this.model.paymentCompanyType == 'OTHER' && !emailRegex.test(this.model.paymentCompanyEmail)){
           this.showMessage('warn', 'wrongEmailAddress');
           return;
       }


    if(!this.model.shipper?.id && !this.model.shipperText) validity = false;
    if(!this.model.consignee?.id && !this.model.consigneeText) validity = false;

    if(!validity) {
      this.showMessage('warn', 'shipperOrConsigneeNotNull');
      return;
    }

    this.parent.reservationInfoLoading = true;
    this.isLoading = true;


    try {
      this.model = await this.airSearchService.shipment(this.model).toPromise();
      this.parent.container.clear();
      this.parent.model = this.model;
      this.stepperService.changeCurrentStep(4);
      this.parent.renderStepComponent(this.parent.stepComponents[4], this.model, 4);
      this.isLoading = false;

    } catch (error) {
        if (error === 'shipmentNotAllowed') this.parent.closeDialog();

        this.parent.isLoading = false;
        this.parent.reservationInfoLoading = false;
        this.isLoading = false;
        }

  }
  async onChangeInsure(event) {
    this.model.selectedProduct.freights.grandTotal =  event.checked ?  this.model.selectedProduct.freights.grandTotal + this.model.insurance : this.model.selectedProduct.freights.grandTotal - this.model.insurance;
    this.calculateAgencyFee();
  }

    calculateAgencyFee() {
        if (!(this.model?.paymentCompanyType == 'CUSTOMER' && this.user?.isAgency && !this.model?.agencyFeeAdded) && !this.model?.selectedDueDate) {

            if (this.model?.paymentCompanyCountry != null && !this.model.paymentCompanyCountry?.name?.includes('TÜRKİYE') && !this.model.paymentCompanyCountry?.name?.includes('TURKEY') && !this.model.agencyFeeAdded) {
                this.model.agencyFee = Number((this.model.selectedProduct.freights.grandTotal * 0.05).toFixed(2));
                if (this.model?.selectedProduct?.freights?.currency == 'USD') {
                    if (this.model.agencyFee < 25) this.model.agencyFee = 25;
                } else {
                    if (this.model.agencyFee < 20) this.model.agencyFee = 20;
                }
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal + this.model.agencyFee;
                this.model.agencyFeeAdded = true;
            } else if (this.model?.paymentCompanyCountry != null && !this.model.paymentCompanyCountry?.name?.includes('TÜRKİYE') && !this.model.paymentCompanyCountry?.name?.includes('TURKEY') && this.model.agencyFeeAdded) {
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal - this.model.agencyFee;
                this.model.agencyFee = Number((this.model.selectedProduct.freights.grandTotal * 0.05).toFixed(2));
                if (this.model?.selectedProduct?.freights?.currency == 'USD') {
                    if (this.model.agencyFee < 25) this.model.agencyFee = 25;
                } else {
                    if (this.model.agencyFee < 20) this.model.agencyFee = 20;
                }
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal + this.model.agencyFee;
                this.model.agencyFeeAdded = true;
            } else if (this.model.agencyFeeAdded && (this.model.paymentCompanyCountry?.name?.includes('TURKEY') || this.model.paymentCompanyCountry?.name?.includes('TÜRKİYE'))) {
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal - this.model.agencyFee;
                this.model.agencyFeeAdded = false;
                this.model.agencyFee = null;
            }
        } else if (this.model?.paymentCompanyType == 'CUSTOMER' && this.user?.isAgency && this.model?.agencyFeeAdded){
            this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal - this.model.agencyFee;
            this.model.agencyFeeAdded = false;
            this.model.agencyFee = null;
        }
    }

  onBlurShipper(event) {
    this.shipperText = event.target.value;
      if (this.model?.paymentCompanyType == 'SHIPPER') this.setPaymentCompany('SHIPPER');
  }
  onBlurConsigne(event) {
    this.consigneText = event.target.value;
      if (this.model?.paymentCompanyType == 'CONSIGNEE') this.setPaymentCompany('CONSIGNEE');
  }
   onInputShipperOrConsignee(fieldName: string) {
      setTimeout(() => {
          if(!this.model?.shipper?.id && fieldName == 'shipper' && this.isSelectedShipper) {
              this.model.shipperAddress = null;
              this.model.shipperCity = null;
              this.model.shipperCountry = null;
              this.model.shipperEmail = null;
              this.model.shipperPhone = null;
              this.isSelectedShipper = false;
          }
          if(!this.model?.consignee?.id && fieldName == 'consignee' && this.isSelectedConsignee) {
              this.model.consigneeAddress = null;
              this.model.consigneeCity = null;
              this.model.consigneeCountry = null;
              this.model.consigneeEmail = null;
              this.model.consigneePhone = null;
              this.isSelectedConsignee = false;

          }
          if(!this.model?.paymentCompany?.id && fieldName == 'paymentCompany' && this.isSelectedPaymentCompany) {
              this.model.paymentCompanyAddress = null;
              this.model.paymentCompanyCity = null;
              this.model.paymentCompanyCountry = null;
              this.model.paymentCompanyEmail = null;
              this.model.paymentCompanyPhone = null;
              this.isSelectedPaymentCompany = false;

          }
      }, 100);

  }

    /* Todo  */
  onFocusOutShipper(event) {  }

  showUseTrms() {
    this.termsDialog = this.dialogService.open(DocViewerDialogComponent, { header: "view", showHeader: false, modal:true, closable: true,  width: '50%', height: '100%',  data: {filePath: `/assets/data/documents/air_${this.translation.currentLang}.html`}});

  }
  async showAirPriceInfo() {
    this.priceInfoDialogRef = this.dialogService.open(AirPriceDetailDialogComponent, { header: null, showHeader: false, modal:true,  width: '800px', height: 'auto', closable: false,  data: {product: this.model.selectedProduct, model: this.model}});
  }

   async checkCompanyTypes(val: any) {
        if (val.value == 'OTHER'){
            this.isSelectedConsignee = true;
            this.model.paymentCompany = null;
            this.model.paymentCompanyCountry = null;
            this.model.paymentCompanyCity = null;
            this.model.paymentCompanyEmail = null;
            this.model.paymentCompanyPhone = null;
            if (this.model.agencyFeeAdded){
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal - this.model.agencyFee;
                this.model.agencyFeeAdded = false;
                this.model.agencyFee = null;
            }
        }
        if (val.value == 'CUSTOMER'){
          this.setCustomer();
        }
        if (val.value == 'SHIPPER'){
            this.setPaymentCompany('SHIPPER');
        }
        if (val.value == 'CONSIGNEE'){
            this.setPaymentCompany('CONSIGNEE');
        }
        this.calculateAgencyFee();

   }

    private setPaymentCompany(val) {
        if (val == 'SHIPPER'){
            this.model.paymentCompany = this.model.shipper;
            this.model.paymentCompanyCountry = this.model.shipperCountry;
            this.model.paymentCompanyCity = this.model.shipperCity;
            this.model.paymentCompanyEmail = this.model.shipperEmail;
            this.model.paymentCompanyPhone = this.model.shipperPhone;
            this.model.paymentCompanyAddress = this.model.shipperAddress;
            this.model.paymentCompany = this.model.shipper;

            if (!this.model.shipper?.id) {
                this.model.paymentCompany = new CompanySearchResultModel();
                this.model.paymentCompany.name = this.shipperText;
            }
        }
        else if (val == 'CONSIGNEE'){
            this.model.paymentCompany = this.model.consignee;
            this.model.paymentCompanyCountry = this.model.consigneeCountry;
            this.model.paymentCompanyCity = this.model.consigneeCity;
            this.model.paymentCompanyEmail = this.model.consigneeEmail;
            this.model.paymentCompanyPhone = this.model.consigneePhone;
            this.model.paymentCompanyAddress = this.model.consigneeAddress;
            this.model.paymentCompany = this.model.consignee;

            if (!this.model.consignee?.id) {
                this.model.paymentCompany = new CompanySearchResultModel();
                this.model.paymentCompany.name = this.consigneText;
            }

        }

    }

    onBlurPaymentCompany(event) {
        this.paymentCompanyText = event.target.value;
    }

    checkAgency() {
      /*  if (this.model?.paymentCompanyType == 'CONSIGNEE' && (this.model.consignee.country.name.includes('TURKEY') || this.model.consignee.country.name.includes('TÜRKİYE'))){

        }

       */
    }

    onSelectPaymentCompany() {
        this.isSelectedPaymentCompany = true;
        this.model.paymentCompanyCountry = null;
        this.model.paymentCompanyCity =  null;
        this.model.paymentCompanyPhone = null;
        this.model.paymentCompanyEmail = null;

        if(this.model?.paymentCompany?.id) {
            console.log(this.model.paymentCompany,'paymentCOmpany');
            this.model.paymentCompanyCountry =  this.model.paymentCompany?.country;
            this.model.paymentCompanyAddress =  this.model.paymentCompany?.address;
            this.model.paymentCompanyCity =  this.model.paymentCompany?.city;
            this.model.paymentCompanyEmail = this.model.paymentCompany?.email;
            this.model.paymentCompanyPhone = this.model.paymentCompany?.phone;
        }

            this.calculateAgencyFee();
    }

    checkCompanyForPayment(text: string) {
      setTimeout(() => {
        if (this.model?.paymentCompanyType  == 'SHIPPER' && text == 'SHIPPER'){
            this.setPaymentCompany('SHIPPER');
            this.calculateAgencyFee();
        }
        if (this.model?.paymentCompanyType  == 'CONSIGNEE' && text == 'CONSIGNEE'){
            this.setPaymentCompany('CONSIGNEE');
            this.calculateAgencyFee();
        }
        if (this.model?.paymentCompanyType  == 'OTHER' && text == 'OTHER'){
              this.calculateAgencyFee();
        }
      }, 100);
    }

}
