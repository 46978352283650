import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sumPipe'
})
export class SumPipe implements PipeTransform{
    constructor() { }

    async transform(data: any, attr: string, fixed:boolean = false) {
      if(!data || data.length == 0) return 0;
      const a = data.filter(f => f[attr]);
      if(!a || a.length == 0) return 0;
      const total = a.reduce((a, b) => a + b[attr], 0);
      return fixed ? Number(total).toFixed(2) : total;
    }
}