import {Component, OnInit, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import { DashboardListResultCount } from 'src/app/models/air/list/dashboard-list-result-counts';
import { ListService } from 'src/app/services/list/list.service';
import { AppMainComponent } from 'src/app/app.main.component';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {


    dashboardListResultCountsDTO: DashboardListResultCount = new DashboardListResultCount();

    constructor(public router: Router, private listService: ListService, public appMain: AppMainComponent, ) { }

    async ngOnInit() {
        this.dashboardListResultCountsDTO = await this.listService.getListCount().toPromise();

    }

    navitage(path, params) {
        this.router.navigate([`/${path}`], params)
    }

    ngAfterViewInit(): void {
        this.appMain.appendScriptSrc('/assets/js/zoho.js')
    }

    protected readonly location = location;
}
