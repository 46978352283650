import {Component, Injector, OnDestroy, OnInit, Inject} from '@angular/core';
import {BaseFormComponentComponent} from "../../shared/base-form-component/base-form-component.component";
import {LoginDto} from "../../models/auth/login-dto.model";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {AuthenticationService} from "../../services/user/authentication.service";
import {UserService} from "../../services/user/user.service";
import {Router} from "@angular/router";
import {ChangeLanguageService} from "../../services/change-language.service";
import {AdminDialogComponent} from "../admin-dialog/admin-dialog.component";
import {ForgotPasswordDialogComponent} from "../forgot-password-dialog/forgot-password-dialog.component";
import {ActivationDialogComponent} from "../activation-dialog/activation-dialog.component";
import { LandingPageComponent } from 'src/app/pages/landing-page/landing-page.component';
import { UserModel } from 'src/app/models/auth/user.model';

@Component({
  selector: 'app-landpage-login',
  templateUrl: './landpage-login.component.html',
  styleUrls: ['./landpage-login.component.scss']
})
export class LandpageLoginComponent extends BaseFormComponentComponent implements OnInit, OnDestroy  {
    isDropdownOpen: boolean = false;

    loginDTO: LoginDto = new LoginDto();
    displayBasic: boolean;
    adminDialogRef: DynamicDialogRef;
    hide: boolean = true;
    forgotDialogRef: DynamicDialogRef;
    activationDialogRef: DynamicDialogRef;
    userModel: UserModel = null;

    constructor(protected injector: Injector, @Inject(LandingPageComponent) public parent: LandingPageComponent, private authService: AuthenticationService, private userService: UserService, private router: Router, private languageService: ChangeLanguageService) {
        super(injector);
    }



    ngOnInit() {
        if(localStorage.getItem('token')) {
            Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))
        }
    }

    ngOnDestroy() {
        if (this.adminDialogRef) {
            this.adminDialogRef.close();
        }
    }
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    changeLanguage(lang: string) {
        this.translation.use(lang);
        localStorage.setItem('lang', lang);
        this.languageService.languageChanged.next(lang);
        this.toggleDropdown();
    }

    goToDashboard() {
        this.router.navigate(['dashboard']);
    }

    async showAdminAuthDialog() {
        const header = await this.translation.get('continueAsAdmin').toPromise();
        this.adminDialogRef = this.dialogService.open(AdminDialogComponent, { header: header, width: '600px', height: 'auto', closeOnEscape: false, baseZIndex: 999999, closable: false,  data: {loginDTO: this.loginDTO}});
    }

    async authenticate() {

        // this.showActivationConfirmDialog();
        // return;
        if(!this.loginDTO.email || !this.loginDTO.password) {
            this.showMessage('warn', 'enterEmailAndPass');
            return;
        }



        this.isLoading = true;
        localStorage.removeItem('token');
        localStorage.removeItem('sessionId');

        try {
            const auth = await this.authService.login(this.loginDTO.email, encodeURIComponent(this.loginDTO.password)).toPromise();



            let user = JSON.stringify(auth.user);
            this.userModel = JSON.parse(user)


            let token = auth.token;
            let sessionId = auth.sessionId;

            localStorage.setItem("token", token);
            localStorage.setItem("sessionId", sessionId);
            localStorage.setItem('user', JSON.stringify(user));


            if(this.userModel) {
                this.userLocale = this.userModel.locale
                const lang = this.userLocale == 'tr-TR' ? 'tr' : 'en';
                this.changeLanguage(lang);
            }


            if(auth.user.status == 'NW'){
                this.showActivationConfirmDialog();
                this.isLoading = false;
                return;
            }

            if(!auth.isManager) {
                this.router.navigate(['/home']).then(r => {
                    if(auth.existUserAuth) {
                        this.translation.get('userExistAuth').toPromise().then(msg => {
                            setTimeout(() => this.showMessage('warn',  `${msg} <br/> <b>IP:</b> ${auth.existUserAuth.ip} - ${new Date(auth.existUserAuth.date).toLocaleDateString()}`), 1000)
                        })
                    }
                })
                return;
            }
            this.isLoading = false;
            await  this.parent.showAdminAuthDialog(this.loginDTO);


        } catch(e) {

            if (e?.error?.message == 'failCountError'){
                this.showForgotDialog();
            }
            this.isLoading = false
        }


    }


    async showForgotDialog() {
        this.forgotDialogRef = this.dialogService.open(ForgotPasswordDialogComponent, { header: null, showHeader: false, styleClass: 'forgot-dialog-modal', modal:true, contentStyle: 'border-radius: 10px;', style: 'border-radius: 10px;', width: '340px', height: 'auto', closable: false});
    }

    showActivationConfirmDialog() {
        this.forgotDialogRef = this.dialogService.open(ActivationDialogComponent, { header: null, showHeader: false, modal:true,  width: '500px', height: '500px', closable: false });
    }

}
