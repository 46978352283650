import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import { MinDTO } from "src/app/models/min-dto";

@Injectable()
export class ContainerTypeService extends BaseService<any>{
  constructor(public httpClient: HttpClient) {
    super(
      'sea',
      httpClient);
  }
  

  containers(): Observable<MinDTO[]> {
    let uri = new URL(environment.baseUrl + `/sea/containers`)

    return this.httpClient.get<MinDTO[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
}


}
