import { Component, OnInit, Injector, Inject } from '@angular/core';
import { SeaComponent } from '../sea.component';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import {Router} from "@angular/router";
import {
    AirPriceDetailDialogComponent
} from "../../../components/air-price-detail-dialog/air-price-detail-dialog.component";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {
    SeaPriceDetailDialogComponent
} from "../../../components/sea-price-detail-dialog/sea-price-detail-dialog.component";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class SeaConfirmationComponent  extends BaseFormComponentComponent implements OnInit {

  currentStep: number;
    priceInfoDialogRef: DynamicDialogRef;

  constructor(
    protected injector: Injector, @Inject(SeaComponent) private parent: SeaComponent,private router: Router) {
    super(injector)
    this.parent.isLoading = true;
  }

    async showSeaPriceInfo() {
        this.priceInfoDialogRef = this.dialogService.open(SeaPriceDetailDialogComponent, { header: null, showHeader: false, modal:true,  width: '800px', height: 'auto', closable: false,  data: {product: this.parent.model.selectedProduct, model: this.parent.model}});
    }
  ngOnInit(): void {
      //this.parent.isConfirmation = true;
  }

    goList() {
        this.router.navigate(['/home/air/list/reservation'], { queryParams: { operation: 'sea'} });
    }

}
