import { Component, OnInit, Injector } from '@angular/core';
import { AuthenticationService } from '../services/user/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponentComponent } from '../shared/base-form-component/base-form-component.component';

@Component({
  selector: 'app-verify',
  template: `<p-toast key="html-msg" [baseZIndex]="10000"></p-toast>`,
})
export class VerifyComponent extends BaseFormComponentComponent implements OnInit {

  constructor(protected injector: Injector, private authService: AuthenticationService, private activatedRoute: ActivatedRoute, private router: Router) {
    super(injector);
  }
 async ngOnInit() {
    if(this.activatedRoute.snapshot.params.code) {
      try {
        await  this.authService.verify(this.activatedRoute.snapshot.params.code).toPromise();
        this.router.navigate(['/']);
        setTimeout(() => this.showMessage('success', 'verifiedAccount'), 1000)
      } catch (err) {
        setTimeout(() => this.router.navigate(['/']), 2000)
      }
       
    }
  }

}
