import { Component, ComponentFactoryResolver, Injector, Input, AfterViewInit, OnInit, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { StepperService } from '../../stepper.service';
import { Subscription } from 'rxjs';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeaSearchComponent } from './search/search.component';
import { SeaPriceRequestComponent } from './price-request/price-request.component';
import { SeaReservationInformationComponent } from './reservation-information/reservation-information.component';
import { SeaConfirmationComponent } from './confirmation/confirmation.component';
import { MinDTO } from 'src/app/models/min-dto';
import { ContainerTypeService } from 'src/app/services/container-type/container-type.service';
import { SelectBoxItemModel } from 'src/app/models/select-box-item.model';
import {SearchDetailDialog} from "../../components/search-detail-dialog/search-detail-dialog.component";
import { PStepsComponent } from 'src/app/framework/p-steps/p-steps.component';


@Component({
  selector: 'app-sea',
  templateUrl: './sea.component.html',
  styleUrls: ['./sea.component.scss']
})

export class SeaComponent extends BaseFormComponentComponent implements OnInit, AfterViewInit, OnDestroy {


  private unsubscriber : Subject<void> = new Subject<void>();

  @Input("step") step: number = 1
  @Input("showOnlyComponent") showOnlyComponent: boolean = false;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild("stepper", { static: false }) stepper: PStepsComponent;

  model = new SeaModel();
  showRigthPanel: boolean = true;
    panelDisplayOption: any =  {
        showInfo: true,
        showLocation: true,
        showCargo: true,
    }

    containerTypes: MinDTO[] = [];
  freightTypes: SelectBoxItemModel[] = [{code: 'F', name: 'FCL'}, {code: 'P', name: 'LCL'}];

  productLoading: boolean = false;
  searchInfoLoading: boolean = false;
  reservationInfoLoading: boolean = false;
  initialOffsetTop: number = 0;
  parentComponent: SearchDetailDialog;
  isConfirmation = false;
  stepSubscription: Subscription;
  stepComponents = {
    1: SeaSearchComponent,
    2: SeaPriceRequestComponent,
    3: SeaReservationInformationComponent,
    4: SeaConfirmationComponent
  }

  constructor(protected injector: Injector, private seaContainerTypeService: ContainerTypeService, private componentFactoryResolver: ComponentFactoryResolver, private stepperService: StepperService) {
    super(injector);
    this.model.operationType = "FCL";
  }

  async ngOnInit() {

    this.containerTypes = await this.seaContainerTypeService.containers().toPromise();


    if(!this.showOnlyComponent) {
      this.stepperService.changeCurrentStep(this.step);
    }




    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {

      if(this.stepperService.step == 3) {
        this.container.clear();
        this.stepperService.changeCurrentStep(1);
      } else {
        this.container.clear();
        this.stepperService.changeCurrentStep(this.stepperService.step -1);
      }

    });


  }



  ngAfterViewInit(): void {

      setTimeout(() => this.initialOffsetTop = document.querySelector<HTMLElement>(".header-img")?.offsetHeight, 500)

      /* Stepper'dan step numarası değiştirildiğinde... ilgili adıma ait component renderlanıyor.  */
      this.stepSubscription = this.stepperService.currentStep.subscribe((step: number) => {

        if(!this.showOnlyComponent) this.renderStepComponent(this.stepComponents[step]);
      })
  }

  ngOnDestroy(): void {
    this.stepSubscription.unsubscribe();
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  closeDialog(){
      if (this.parentComponent) this.parentComponent.closeDialog();
  }


  renderStepComponent(comp: any, seaModel: SeaModel = this.model, step = 1) {


    if(step == 4 && this.showOnlyComponent) {
      this.panelDisplayOption.showInfo = false;
      this.panelDisplayOption.showLocation = false;
    }

    if( !this.showOnlyComponent && ((step == 3 && !this.model?.selectedProduct) || this.model.shipmentNo) && (step != 4) ) {
        this.showRigthPanel = false;
    }


    this.container.clear();
    window.scroll({ top: 0,  left: 0, behavior: 'smooth' });

    switch ( step ) {
        case 1:
            this.searchInfoLoading = true;
            break;
        case 2:
            this.productLoading = true;
            break;
        case 3:
            this.reservationInfoLoading = true;
            break;
        case 4:
            this.reservationInfoLoading = true;
            break;
        default:
            this.isLoading = true;
            break;
    }

    // create the component factory
    const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(comp);
    // add the component to the view
    const componentRef = this.container.createComponent(dynamicComponentFactory);
    componentRef.instance['model'] = seaModel;

    window.scroll({ top: this.initialOffsetTop,  left: 0, behavior: 'smooth' });

    this.isLoading = false;
    this.productLoading = false;
    this.reservationInfoLoading  = false;
    this.searchInfoLoading = false;

  }

}
