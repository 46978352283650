import {Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor} from "@angular/forms";
import {SimpleEntity} from "../../models/simple-entity.model";
import {AutocompleteAccessor} from "./autocomplete-accessor";
import {AutoComplete} from "primeng/autocomplete";

import {AirportService} from "../../services/air/airport.service";
import {CountryService} from 'src/app/services/country/country.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { CityService } from 'src/app/services/city/city.service';
import { SeaportService } from 'src/app/services/sea/seaport.service';
import { ZipService } from 'src/app/services/zip/zip.service';
import { TaxOfficeService } from 'src/app/services/taxOffice/taxOffice.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [AutocompleteAccessor],
  encapsulation: ViewEncapsulation.None
})




export class AutocompleteComponent implements OnInit, ControlValueAccessor, OnChanges {


  @Input() values: any[] = [];
  @Input() field: string = "name";
  @Input() concatField: string = null;
  @Input() placeholder: string;
  @Input() parentId: number = 0;
  @Input() serviceName: string;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input("isDropDown") isDropDown: boolean = false;
  @Input() defaultValue: any = null;
  @Input() params: any = null;
  @Input() focusOutDisable: boolean = false;
  @Input() forceSelect: boolean = true;
  @Input() otherItemValue: any = null;
  @Input() inputStyleClass: any = null;
  @Input() minCharLimit: number = 3;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onClear: EventEmitter<any> = new EventEmitter();
  @Output() focusOut: EventEmitter<any> = new EventEmitter();
  @Output() unSelect: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  @Output() keyup: EventEmitter<any> = new EventEmitter();

  @ViewChild(AutoComplete) private autoComplete: AutoComplete;

filtered: SimpleEntity[] = [];

  /* if getAll is true, execute getAll function in service
  *  if useFilter is true, filter response data from client side.
  */
  private services =  {
    'country': {'service': CountryService, getAll: false, useFilter: false},
    'city':    {'service': CityService, getAll: false, useFilter: false},
    'airport': {'service': AirportService, getAll: false, useFilter: false},
    'seaport': {'service': SeaportService, getAll: false, useFilter: false},
    'company': {'service': CompanyService, getAll: false, useFilter: false},
    'taxOffice': {'service': TaxOfficeService, getAll: false, useFilter: false},
    'zip': {'service': ZipService, getAll: false, useFilter: false},
    'zipSearchByText': {'service': ZipService, getAll: false, useFilter: false},
  }

  private innerValue: any = null;
  items: any[] = [];

  //by the Control Value Accessor
  private onTouchedCallback: () => void =  () => {};
  private onChangeCallback: (_: any) => void =  () => {};

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {

    // multiple display field ayarlanıyor.
    this.setNameMultipleName();


    if(value === null) this.innerValue = null;

    if (value !== this.innerValue && !this.value) {
      this.innerValue = null;
    }
    this.value = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }


  constructor(protected injector: Injector, private translateService: TranslateService) {  }

  Tr2En(text){
    var Maps = {
      "İ":"I","Ş":"S","Ç":"C","Ğ":"G","Ü":"U","Ö":"O",
      "ı":"i","ş":"s","ç":"c","ğ":"g","ü":"u","ö":"o"
    };
    Object.keys(Maps).forEach(function(Old){
      text    = text.replace(Old,Maps[Old]);
    });
    return text;
  }

  filterResult(query) {
    const filtered: any[] = [];
    this.items.forEach(v => {
        if (this.Tr2En(v.name.toLocaleUpperCase('tr-TR')).indexOf(this.Tr2En(query.toLocaleUpperCase('tr-TR'))) === 0)  filtered.push(v);
    });
    return filtered;
  }

  async filter(event) {

    if (event.query.length === 0 && this.items.length > 0) {
      this.filtered = this.items;
      this.autoComplete.show();
      this.autoComplete.loading = false;
    }
    if (event.query.length === 0 && this.values.length > 0) {
      this.filtered = this.values;
      this.autoComplete.show();
      this.autoComplete.loading = false;
    }
    if(!event.query || event.query.length < this.minCharLimit) return;

    if(this.serviceName && this.services[this.serviceName].getAll) {
      this.filtered = this.filterResult(event.query);
    } else {

      // parentId yoksa...
      if(this.parentId != 0 && this.parentId == null) return;

      if(this.params) {
        this.items = this.serviceName == 'zipSearchByText' ?
            await this.injector.get(this.services[this.serviceName].service).searchByText(this.params).toPromise()
            : await this.injector.get(this.services[this.serviceName].service).autocomplete(this.params).toPromise();
        this.addDefaultItem();
      } else {
          if(this.values.length > 0 ) {
              this.items = this.values;
              this.addDefaultItem();
              this.filtered = this.filterResult(event.query);
              return;
          } else {
              if (this.serviceName) {
                  this.items = await this.injector.get(this.services[this.serviceName].service).autocomplete((this.parentId || event.query)).toPromise();
                  this.addDefaultItem();
              }
          }
      }
      if (this.serviceName){
          this.filtered =  this.services[this.serviceName].useFilter ? this.filterResult(event.query) : this.items;
      }else {
          this.filtered = this.filterResult(event.query);
      }

      // multiple display field ayarlanıyor.
      this.setNameMultipleName();

    }
  }

  onFocusOut() {
      if (!this.focusOutDisable){
          this.focusOut.emit(this.value);
          if(this.forceSelect) {
            if(!this.value || !this.value.id) this.value = null;
            if(!this.value) this.unSelect.emit();
          }

      }
  }

  async addDefaultItem() {
    if(this.otherItemValue && !this.otherItemValue?.id) {
      const otherTxt = await this.translateService.get('other').toPromise();
      //`${this.otherItemValue.replace(/\D/g,'')} (${otherTxt})`,
      this.items.push({id: -1, name: `${this.otherItemValue} (${otherTxt})`, isOptionOther: true});
    }
  }

  async ngOnInit() {
    this.items = this.values;


    if(this.defaultValue) this.value = this.defaultValue;

  }

  setNameMultipleName() {
       if(this.concatField) {
        this.filtered.forEach(item => {
          if(!item.id || item.id < 0 ) return;
          let label = [];
          this.concatField.split(',').forEach(key => {
              label.push(item[key]);
          })
          item['name'] = label.join(' ');
          item['optionOtherLabel'] = label.join(' ');
        })
      }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {  }
}
