import { Component, Inject } from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {SeaModel} from "../../models/sea/sea-model";
import {MarketSeaProductsModel} from "../../models/sea/market-sea-products.model";
import { SeaCalculatedChargesModel } from 'src/app/models/sea/sea-calculated-charges.model';
import { SurcharDetailDialog } from '../surcharge-detail-dialog/surcharge-detail-dialog.component';
import { FrontEndTariffModel } from 'src/app/models/front-end-tariff.model';
import { ChangeLanguageService } from 'src/app/services/change-language.service';
import { MarketPlaceDueDatesHeaderDetail } from 'src/app/models/market-place-due-dates-header-detail.model';
import { SeaComponent } from 'src/app/pages/sea/sea.component';
import { SeaSearchService } from 'src/app/services/sea/sea-search.service';

@Component({
  selector: 'app-sea-price-detail-dialog',
  templateUrl: './sea-price-detail-dialog.component.html',
  styleUrls: ['./sea-price-detail-dialog.component.scss']
})
export class SeaPriceDetailDialogComponent {
    product: MarketSeaProductsModel = null;
    model: SeaModel = null;
    lang: string = "tr";
    surchargeDialogRef: DynamicDialogRef;
    parent: SeaComponent = null;

    constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, public dialogService: DialogService, private seaService: SeaSearchService, private languageService: ChangeLanguageService) {
        this.product = this.config.data.product;
        this.model = this.config.data.model;
        this.lang =  this.languageService.getCurrentLanguage();
        this.parent = this.config.data.parentComponent;


    }

    async showSurchages(surcharge: SeaCalculatedChargesModel) {
        this.surchargeDialogRef = this.dialogService.open(SurcharDetailDialog, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { surcharge: surcharge } });

        //Click History sorulacak.
        /* this.createClickHistory(surcharge?.name); */
      }

      async showFrontEndSurchages(surcharge: FrontEndTariffModel) {
        this.surchargeDialogRef = this.dialogService.open(SurcharDetailDialog, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { surcharge: surcharge } });

        //Click History sorulacak.
        /* this.createClickHistory(surcharge?.name); */
      }

    closeDialog() {
        this.ref.close();
    }

    async onApproveDue(product: MarketSeaProductsModel) {
      this.product = product;
      const airModel = await this.seaService.insurance(this.model).toPromise();
      this.model.insurance = airModel.insurance;
      this.parent.showMessage('success', product?.selectedDue ? 'approvedDueDay' : 'approvedCash');
    }
}
