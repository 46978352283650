import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import { CountryMinimizedModel } from "src/app/models/country-minimized.model";

@Injectable()
export class CountryService extends BaseService<any>{
  constructor(public httpClient: HttpClient) {
    super(
      'country',
      httpClient);
  }
  autocomplete = this.searchCountry;

  searchCountry(name: string): Observable<CountryMinimizedModel[]> {
    let uri = new URL(environment.baseUrl + `/country`).updateQueryParam({name});
    return this.httpClient.get<CountryMinimizedModel[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
      .pipe(
        tap(),
        catchError(this.handleError)
      )
  }

}
