import { AbstractModel } from "../abstract-model";
import { MarketAirProductsModel } from "./market-air-products.model";
import { AirPackModel } from "./air-pack-model";

export class AirModel extends AbstractModel {

    // TOTAL / DIMENSION
    uselbInch: boolean = false;
    // total / dimessions
    td: string = 'T';


    // PACK
    totalWeight: number = null;
    totalVolume: number = null;
    cwg: number = null;
    pieces: number;
    stackable: boolean = true;

    // PACKS
    packs: AirPackModel[] = [];

    calculatedPieces: number;

    // PRODUCT
    products: MarketAirProductsModel[] = [];
    selectedProduct: MarketAirProductsModel;

}
