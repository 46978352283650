import { Component, OnInit, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-tariff-not-found-dialog',
  templateUrl: './tariff-not-found-dialog.component.html',
  styleUrls: ['./tariff-not-found-dialog.component.scss']
})
export class TariffNotFoundDialogComponent implements OnInit{

  

  @ViewChild("dialogContent", { read: ViewContainerRef }) dialogContent;
  priceInfoDialogRef: DynamicDialogRef;
  type = this.config.data.type;
  
  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
  }

  async ngOnInit() {
 
  }

  closeDialog() {
    this.ref.close();
  }

}
