import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef} from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/services/user/authentication.service';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';


@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordDialogComponent extends BaseFormComponentComponent{

  email: string = null;

  constructor(protected injector: Injector, public ref: DynamicDialogRef, private authService: AuthenticationService) {
    super(injector);
  }

  closeDialog() {
    this.ref.close();
  }

  async forgot() {


    const valid = await this.isValid();

    if (!valid) return;

    this.isLoading = true;

    try {
      await this.authService.forgot(this.email,  this.translation.currentLang).toPromise();
      this.isLoading = false;
      this.closeDialog();
      this.email = null;
      this.showMessage('success', 'forgotEmailSended')
    } catch {
      this.isLoading = false;
    }


  }
}
