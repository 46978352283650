import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import { CompanySearchResultModel } from "src/app/models/company/company-search-result.model";


@Injectable()
export class CompanyService extends BaseService<any>{
  constructor(public httpClient: HttpClient) {
    super(
      'company',
      httpClient);
  }

  autocomplete = this.searchCompanyByName;

  searchCompanyByName(params): Observable<CompanySearchResultModel[]> {
    let uri = new URL(environment.baseUrl + '/company').updateQueryParam(params)
    return this.httpClient.get<CompanySearchResultModel[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
      .pipe(
        tap(),
        catchError(this.handleError)
      )
  }

}
