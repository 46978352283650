import { Component, OnInit, Input, Injector, OnDestroy, Inject } from '@angular/core';
import { StepperService } from 'src/app/stepper.service';
import { TruckModel } from 'src/app/models/truck/truck-model';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { TruckComponent } from '../truck.component';
import { TruckSearchService } from 'src/app/services/truck/truck-search.service';
import { DocViewerDialogComponent } from 'src/app/components/doc-viewer-dialog/doc-viewer-dialog.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TruckPriceDetailDialogComponent } from 'src/app/components/truck-price-detail-dialog/truck-price-detail-dialog.component';


@Component({
  selector: 'app-reservation-information',
  templateUrl: './reservation-information.component.html',
  styleUrls: ['./reservation-information.component.scss']
})
export class TruckReservationInformationComponent extends BaseFormComponentComponent implements OnInit, OnDestroy{

  @Input("model") model: TruckModel;

  currentStep: number;
  isCheckboxSelected: boolean = false;
  disableInsuranceCountry = ['CU','MM','IR','IQ','LB','UA','BY','SY','KR','SO','ZW','LY','AF','YE','SD'];
  shipperText: string = "";
  consigneText: string = "";
  isApproveSelected: boolean = false;
  termsDialog: DynamicDialogRef;
  priceInfoDialogRef: DynamicDialogRef;

  constructor(protected injector: Injector,
     @Inject(TruckComponent) private parent: TruckComponent, private stepperService: StepperService, private truckSearchService: TruckSearchService) {
    super(injector);
  }


  ngOnInit(): void {}

  ngOnDestroy(): void {
    // CompanySearchResultModel tipine string değer setlendiği için bir önceki steplerde post edildiğinde backend parse hatası veriyor o yüzden siliniyor.
    if(!this.model.shipper?.id) this.model.shipper = null;
    if(!this.model.consignee?.id) this.model.consignee = null;
   }
  onSelectShipper() {
    this.model.shipperCountry = null;
    this.model.shipperAddress = null;
    this.model.shipperCity =  null;

    if(this.model.shipper?.id) {
      this.model.shipperCountry =  this.model.shipper?.country;
      this.model.shipperAddress =  this.model.shipper?.address;
      this.model.shipperCity =  this.model.shipper?.city;
    }
  }

  onSelectConsignee(event) {
    this.model.consigneeCountry = null;
    this.model.consigneeAddress = null;
    this.model.consigneeCity =  null;

    if(this.model.consignee?.id) {
      this.model.consigneeCountry =  this.model.consignee?.country;
      this.model.consigneeAddress =  this.model.consignee?.address;
      this.model.consigneeCity =  this.model.consignee?.city;
    }
   }

   async approve() {

    if(!this.isCheckboxSelected) {
      this.showMessage('warn', 'useConditionRequired');
      return;
    }

    const valid = await this.isValid();
    if (!valid) return;

    let validity = true;
    if(!this.model.shipper?.id) {
      this.model.shipperText = this.shipperText;
      this.model.shipper = null;
    }


    if(!this.model.consignee?.id){
      this.model.consigneeText = this.consigneText;
      this.model.consignee = null;
    }


    if(!this.model.shipper?.id && !this.model.shipperText) validity = false;
    if(!this.model.consignee?.id && !this.model.consigneeText) validity = false;

    if(!validity) {
      this.showMessage('warn', 'shipperOrConsigneeNotNull');
      return;
    }

    this.parent.reservationInfoLoading = true;
    this.isLoading = true;


    try {
      this.model = await this.truckSearchService.shipment(this.model).toPromise();
      this.parent.container.clear();
      this.parent.model = this.model;
      this.stepperService.changeCurrentStep(4);
      this.parent.renderStepComponent(this.parent.stepComponents[4], this.model, 4);
      this.isLoading = false;

    } catch {
      this.parent.isLoading = false;
      this.isLoading = false;
      this.parent.reservationInfoLoading = false;
    }


  }
  async onChangeInsure(event) {
    this.model.selectedProduct.freights.grandTotal =  event.checked ?  this.model.selectedProduct.freights.grandTotal + this.model.insurance : this.model.selectedProduct.freights.grandTotal - this.model.insurance

  }

  onBlurShipper(event) {
    this.shipperText = event.target.value;
  }
  onBlurConsigne(event) {
    this.consigneText = event.target.value;
  }
  onInputShipperOrConsignee(fieldName: string) {
    if(!this.model?.shipper?.id && fieldName == 'shipper') {
      this.model.shipperAddress = null;
      this.model.shipperCity = null;
      this.model.shipperCountry = null;
    }
    if(!this.model?.consignee?.id && fieldName == 'consignee') {
      this.model.consigneeAddress = null;
      this.model.consigneeCity = null;
      this.model.consigneeCountry = null;
    }
  }

    /* Todo  */
  onFocusOutShipper(event) {  }

  showUseTrms() {
    this.termsDialog = this.dialogService.open(DocViewerDialogComponent, { header: "view", showHeader: false, modal:true, closable: true,  width: '50%', height: '100%',  data: {filePath: `/assets/data/documents/truck_${this.translation.currentLang}.html`}});

  }
  async showTruckPriceInfo() {
    this.priceInfoDialogRef = this.dialogService.open(TruckPriceDetailDialogComponent, { header: null, showHeader: false, modal:true,  width: '800px', height: 'auto', closable: false,  data: {product: this.model.selectedProduct, model: this.model}});
  }

}
