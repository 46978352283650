import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DashboardListResultCount } from 'src/app/models/air/list/dashboard-list-result-counts';

@Injectable({
  providedIn: 'root'
})
export class ListService  extends BaseService<any>{

  constructor(public httpClient: HttpClient) {
      super(
          'list',
          httpClient);
  }


  getListCount(): Observable<DashboardListResultCount> {
    let uri = new URL(environment.baseUrl + `/list/count`);
    return this.httpClient.get<DashboardListResultCount>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

  getList(type: string, operationType: string, page? : number): Observable<HttpResponse<any>> {
    let uri = new URL(environment.baseUrl + `/list`).updateQueryParam({ type, operationType, page});
    return this.httpClient.get<any>(uri.toString(), { withCredentials: false, headers: this.httpHeaders(), observe: 'response' });
  }



}
