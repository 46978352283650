import { HttpClient, HttpHeaders, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject, observable} from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import {LoginResponseModel} from "../../models/auth/login-response.model";
import { UserModel } from 'src/app/models/auth/user.model';
import { ForgotPasswordModel } from 'src/app/models/forgot-password.model';
import { ChangePasswordDTO } from 'src/app/models/auth/change-password-dto.model';
import { UserDocumentDTO } from 'src/app/models/auth/user-document.model';


@Injectable()
export class AuthenticationService extends BaseService<any>{
    constructor(public httpClient: HttpClient) {
        super(
            'authentication',
            httpClient);
    }

    changePassword(changePasswordDto: ChangePasswordDTO): Observable<Boolean> {
        let uri = new URL(environment.baseUrl + `/auth/changePassword`);
        return this.httpClient.post<Boolean>(uri.toString(), changePasswordDto, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

   
    login(email:  string, password: string, referenceNo: string = null): Observable<LoginResponseModel> {

        const uri  = new URL(environment.baseUrl + '/auth/login').updateQueryParam({email, password, referenceNo});

        return this.httpClient.get<LoginResponseModel>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    logout(): Observable<String> {
        const uri  = new URL(environment.baseUrl + '/auth/logout');
        return this.httpClient.get<String>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }
     
    register(userModel: UserModel, lang: string = null): Observable<UserModel> {

        const uri  = new URL(environment.baseUrl + `/auth/register/${lang}`);

        return this.httpClient.post<UserModel>(uri.toString(), userModel, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    reset(forgotPasswodModel: ForgotPasswordModel): Observable<String> {

        const uri  = new URL(environment.baseUrl + `/auth/reset`);

        return this.httpClient.post<String>(uri.toString(), forgotPasswodModel, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    forgot(email:  string, lang: string): Observable<String> {
        const uri  = new URL(environment.baseUrl + `/auth/forgot/${lang}`).updateQueryParam({email})
       
        return this.httpClient.get<String>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }


    verify(code: string): Observable<void> {

        const uri  = new URL(environment.baseUrl + `/auth/verify/${code}`);

        return this.httpClient.get<void>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    sendVerification(): Observable<void> {

        const uri  = new URL(environment.baseUrl + `/auth/reverification`);

        return this.httpClient.get<void>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    getToken() {
        return localStorage.getItem('token');
    }
    getUid() {
        return localStorage.getItem('uid');
    }


    getDigitalUrlFromCache(): string {
        const uid = this.getUid();
        let uri = new URL(environment.baseUrl + `/auth/getCacheDocument`).updateQueryParam({uid})
        return uri.toString()
    }

    setCacheDocument(document: UserDocumentDTO): Observable<any> {
        let uri = new URL(environment.baseUrl + `/auth/setCacheDocument`)
        return this.httpClient.post<UserDocumentDTO>(uri.toString(), document,  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

}
