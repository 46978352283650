import { Component, Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'DecimalPipe'})
export class DecimalPipe implements PipeTransform {
  transform(value: number): string {
 
    if(isNaN(value))  return String(0);
    let value_ = String(value)
    let dotIndex = value_.indexOf(".");
    if(dotIndex > -1) {
        return  String(Number(value_.substring(0, dotIndex + 3)).toFixed(2));
    }
    return value_;

  }
}