import {Component, ViewEncapsulation} from '@angular/core';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-landpage-accordion-faq',
  templateUrl: './landpage-accordion-faq.component.html',
  styleUrls: ['./landpage-accordion-faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandpageAccordionFaqComponent {

    faq = [
        {
            title: 'registerTab',
            command: () => this.questionList = this.faq[0].details,
            details: [
                {
                    question: 'registerQ1',
                    answer: 'registerA1'
                },
                {
                    question: 'registerQ2',
                    answer: 'registerA2'
                },
                {
                    question: 'registerQ3',
                    answer: 'registerA3'
                },
                {
                    question: 'registerQ4',
                    answer: 'registerA4'
                },
            ]
        },
        {
            title: 'priceTab',
            command: () => this.questionList = this.faq[1].details,
            details: [
                {
                    question: 'priceQ1',
                    answer: 'priceA1'
                },
                {
                    question: 'priceQ2',
                    answer: 'priceA2'
                },
                {
                    question: 'priceQ3',
                    answer: 'priceA3'
                },
                {
                    question: 'priceQ4',
                    answer: 'priceA4'
                },
                {
                    question: 'priceQ5',
                    answer: 'priceA5'
                },
                {
                    question: 'priceQ6',
                    answer: 'priceA6'
                },
                {
                    question: 'priceQ7',
                    answer: 'priceA7'
                },
                {
                    question: 'priceQ8',
                    answer: 'priceA8'
                },
            ]
        },
        {
            title: 'bookingTab',
            command: () => this.questionList = this.faq[2].details,
            details: [
                {
                    question: 'bookingQ1',
                    answer: 'bookingA1'
                },
                {
                    question: 'bookingQ2',
                    answer: 'bookingA2'
                },
                {
                    question: 'bookingQ3',
                    answer: 'bookingA3'
                },
                {
                    question: 'bookingQ4',
                    answer: 'bookingA4'
                },
                {
                    question: 'bookingQ5',
                    answer: 'bookingA5'
                },
                {
                    question: 'bookingQ6',
                    answer: 'bookingA6'
                },
                {
                    question: 'bookingQ7',
                    answer: 'bookingA7'
                },
            ]
        },
    ];
    activeItem: MenuItem = this.faq[0];
    questionList = this.faq[0].details;

}
