import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { MinDTO } from 'src/app/models/min-dto';


@Injectable()
export class SeaportService extends BaseService<any>{
    constructor(public httpClient: HttpClient) {
        super(
            'sea',
            httpClient);
    }

    autocomplete = this.seachSeaport;

    seachSeaport(params): Observable<MinDTO[]> {
        let uri = new URL(environment.baseUrl + `/sea/port`).updateQueryParam(params);

        return this.httpClient.get<MinDTO[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


}
