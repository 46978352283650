import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'localeDate'
})
export class LocaleDatePipe implements PipeTransform{
    constructor(private datePipe : DatePipe) { }

    transform(date: any, format: string) {
 

      if(date instanceof Date) {
        let dateStr = date.toLocaleDateString();
        date = `${dateStr.split(".")[2]}-${dateStr.split(".")[1]}-${dateStr.split(".")[0]}`;
      } 
      if(!date) return "";

      return this.datePipe.transform(new Date(`${String(date).substring(0,10).trim()}`), format)
    }
}