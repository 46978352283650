import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Injectable, isDevMode } from "@angular/core";
import { MessageService } from "primeng/api";
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

export const errorMessagerSubject$ = new Subject<any>();

@Injectable()
export class ErrorHandlerService {

  constructor(private translation: TranslateService, private messageService: MessageService, private router: Router) {

    errorMessagerSubject$.subscribe(err => {

      // intercept içinde throw edildiğinde tekrar errorMessager trigger oluyor globalErrorMessage hatasını fırlatıyor.
      if(typeof err == 'string') return;


      let msg = err?.message;
      if (err.error?.message) {
        msg = err?.error?.message || JSON.parse(err?.error)?.message
      }

      if(msg == 'userBanned' || msg == 'userBannedForManyRequest') {
        Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))
        if(msg == 'userBannedForManyRequest') setTimeout(() => window.location.href = '/', 2000);
      }

      // token geçerlilik süresi dolmuş vb durum yaşanmış ise.
      if ((err instanceof HttpErrorResponse) && err.status == 401) {

        Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))

        this.translation.get('notAuthorized').subscribe(messageTranslated => {
        
          this.router.navigate(['/']).then(() => {
            // routing den sonra init olmasını bekliyoruz...
            setTimeout(() => this.messageService.add({ severity: 'error', summary: '', detail: messageTranslated }), 1000)
          })
        });
        
        return;

      }

      this.translation.get(msg || 'globalErrorMessage').subscribe(messageTranslated => {

        if (!isDevMode() && messageTranslated == msg) {
          this.translation.get('globalErrorMessage').subscribe(globalErrorMessage => {
            this.messageService.add({ severity: 'error', key:'html-msg', summary: '', detail: globalErrorMessage });
          })
        } else {
          this.messageService.add({ severity: 'error', key:'html-msg', summary: '', detail: messageTranslated });
        }

      })


    })

  }

}


