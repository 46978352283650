import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ErrorHandlerService } from './services/error-handler.service';
import {TranslateService} from '@ngx-translate/core';
import { ChangeLanguageService } from './services/change-language.service';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  menuMode = 'horizontal';

  darkMode = 'light';

  topbarTheme = 'light';

  menuTheme = 'light';

  inputStyle = 'outlined';

  ripple: boolean;

  langugeSubscription: Subscription;

  constructor(private primengConfig: PrimeNGConfig, private errorHandlerService: ErrorHandlerService, private translation: TranslateService, private languageService: ChangeLanguageService ) {
    this.translation.setDefaultLang('en');
    this.translation.use(localStorage.getItem('lang') || 'tr');
   }

  ngOnInit() {
    this.primengConfig.ripple = true;
  //  setTimeout(() => this.translation.use(), 300);

    this.langugeSubscription = this.languageService.languageChanged.subscribe(lang => {
      console.warn('Language is: ' + lang)
      localStorage.setItem('lang', lang);
    })

    //session uid
    if(!localStorage.getItem('uid')) {
      localStorage.setItem('uid', uuidv4());
    }


  }
  ngOnDestroy(): void {
    if(this.langugeSubscription) this.langugeSubscription.unsubscribe();
  }
}
