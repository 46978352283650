import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ChangeLanguageService} from "../../services/change-language.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-landpage-carousel-video',
  templateUrl: './landpage-carousel-video.component.html',
  styleUrls: ['./landpage-carousel-video.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LandpageCarouselVideoComponent implements OnInit, OnDestroy{
    items: any[] = [1, 2];
    page = 0;
    videoUrl: any;
    langugeSubscription: Subscription;

    constructor(private translateService: TranslateService,  private languageService: ChangeLanguageService) {
    }


    protected readonly screen = screen;

    async ngOnInit() {

        if (screen.width < 1200){
            this.items = [1];
        }
        window.onresize = () => {
            if (screen.width < 1200){
                this.items = [1];
            }else {
                this.items = [1, 2];
            }
        };
        this.getVideoUrl();
        this.langugeSubscription = this.languageService.languageChanged.subscribe(lang => {
           this.getVideoUrl();
        });
    }

    async getVideoUrl(){
        this.videoUrl = '/assets/video/' + await this.translateService.get('wopVideo').toPromise();

    }

    ngOnDestroy(): void {
        if (this.langugeSubscription){
            this.langugeSubscription.unsubscribe();
        }
    }

    changePage(forward) {


        if (this.page == 1) {
            this.page = 0;
        }else {
            this.page = 1;
        }
    }

}
