import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';

import { forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EmailAutocompleteComponent),
  multi: true
};


@Component({
  selector: 'app-email-autocomplete',
  templateUrl: './email-autocomplete.component.html',
  styleUrls: ['./email-autocomplete.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class EmailAutocompleteComponent implements OnInit, ControlValueAccessor {

  filteredEmails: any[] = [];
  @Input() emails: string[] = [];

  //The internal data model
  private innerValue: any = null;

  //by the Control Value Accessor
  private onTouchedCallback: () => void =  () => {};
  private onChangeCallback: (_: any) => void =  () => {};

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {}

  isValidEmail(emailAddress) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
  };

  onDropdownClick() {
    this.filteredEmails = this.emails;
  }

  onEnter(event: any) {
    if (event.target.value && event.target.value != "" && event.code == 'Enter') {
      if (!this.isValidEmail(event.target.value)) {
        this.messageService.add({ key: 'tst', severity: 'warning', summary: 'Hata', detail: 'Geçersiz e-posta adresi!' });
        return;
      }
      this.value.push(event.target.value);
      event.target.value = "";
    }
  }

  filterEmail(event) {
    let filtered: any[] = [];
    let query = event.query
    filtered = this.emails.filter(f => f.toLowerCase().includes(query.toLowerCase()));
    setTimeout(() =>  this.filteredEmails = filtered, 100)
  }

}
