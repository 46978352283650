
import { FrontEndTariffModel } from "../front-end-tariff.model";
import { AirCalculatedChargesModel } from "./air-calculated-charges.model";

export class AirCalculatedFreightModel {
    airFreightTariffDetId: number;
    offerAirDetailId: number;
    unitPrice: number;
    cwg: number;
    ratio: number;
    grossWeight: number;
    volume: number;
    totalFreight: number;
    grandTotal: number;
    currencyRate; //kur cevrimi içi: number;
    tariffCurrency: string;
    currency: string;
    errorCode: string;
    freightTariffValidFrom: Date;
    freightTariffValidTo: Date;
    freightTariffDetValidFrom: Date;
    freightTariffDetValidTo: Date;
    isMinFreight: boolean;

    surcharges: AirCalculatedChargesModel[];
    originCharges: AirCalculatedChargesModel[];
    insuranceCharge: AirCalculatedChargesModel;
    agencyFeeCharge: AirCalculatedChargesModel;
    destinationCharges: AirCalculatedChargesModel[];
    frontEndTariffs: FrontEndTariffModel[];
}
