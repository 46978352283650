import { Component, Injector, Input, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AirModel } from 'src/app/models/air/air-model';
import { AirSearchService } from 'src/app/services/air/air-search.service';
import { AirPackModel } from 'src/app/models/air/air-pack-model';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { AirComponent } from '../air.component';
import { DatePipe } from '@angular/common';
import { StepperService } from 'src/app/stepper.service';
import { DecimalPipe } from 'src/app/shared/decimal.pipe';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocViewerDialogComponent } from 'src/app/components/doc-viewer-dialog/doc-viewer-dialog.component';

@Component({
  selector: 'app-air-search',
  templateUrl: './air-search.component.html',
  styleUrls: ['./air-search.component.scss'],
  providers: [DecimalPipe],
  encapsulation: ViewEncapsulation.None
})
export class AirSearchComponent extends BaseFormComponentComponent implements OnInit {

  @Input("model") model: AirModel;
  nowDate = new Date();

  cwValue = 166.666667;
  termsDialog: DynamicDialogRef;
  isCheckboxSelected: boolean = false;

  constructor(protected injector: Injector, private decimalPipe: DecimalPipe,
    @Inject(AirComponent) private parent: AirComponent,
    private stepperService: StepperService, private datePipe: DatePipe,
    private router: Router, private airSearchService: AirSearchService) {
    super(injector)
   }

  ngOnInit(): void {
      this.model.products = null;
      this.model.selectedProduct = null;
      this.model.quoteNo = null;
    if(!this.model.packs || this.model?.packs?.length  == 0 ) this.addPack();

    this.calculateTotals();



    /* p-calendar de dışında farklı bir component kullanılacak */
    if(this.model.readinessDate && !(this.model.readinessDate instanceof Date)) {
      this.model.readinessDate = new Date(`${String(this.model.readinessDate).substring(0,10).trim()}`);
    }

      this.parent.stepper.notShowReservation = false;


  }

  onSelectZip(e: any, elementRef: any, zipField = 'departureZip' ) {
    // Eğer diğer seçildi ise postCode boş oluyor.
    if(e.isOptionOther) {
      elementRef.autoComplete.inputEL.nativeElement.value = e.name.trim().split(' ')[0]
      this.model[zipField].name = e.name.trim().split(' ')[0];
      return
    };

    elementRef.autoComplete.inputEL.nativeElement.value = e['postCode']
    this.model[zipField].name = e['postCode']
  }

  addPack() {
    if(!this.model.packs) this.model.packs = [];
    this.model.packs.push(new AirPackModel());
  }

  removePack(index: number) {
    this.model.packs.splice(index, 1);
    this.calculateTotals();
  }

  offClick(){
    if(this.isCheckboxSelected == false){
      this.showMessage('warn', 'wrongContractAgreement');
    }
  }

  async search(form) {



    let packHasValid = true;


      const valid = await this.isValid();

      if (!valid) return;


    if(this.model.service && this.model.service[0] == 'D' && (!this.model.departureZip && !this.model.departureZipTxt)) {
      this.showMessage('warn', 'departureZipRequired');
      return;
    }
    if(this.model.service && this.model.service[this.model.service.length -1] == 'D' && (!this.model.arrivalZip && !this.model.arrivalZipTxt)) {
      this.showMessage('warn', 'arrivalZipRequired');
      return;
    }

    if(this.model.td == 'D') {
      if(this.model.packs.filter(f => !f.height || !f.lenght || !f.pieces || !f.perKg || !f.width).length > 0 ) {
        packHasValid = false;
      }
    }

    if(!this.model.totalVolume || !this.model.totalWeight || (!this.model.pieces && !this.model.calculatedPieces) ) {
      packHasValid = false;
    }

    if(!packHasValid) {
      this.showMessage('warn', 'totalOrUnitRequired');
      return;
    }

      if(!this.model.commodity) {
          this.showMessage("warn", "commodityRequired");
          return;
      }




    try {

      this.parent.productLoading = true;


      /* Todo:
         tarih payload da 1 gün geri gidiyor nedeni anlaşılarak düzeltme uygulanacak.
       */
     // this.model.readinessDate.setDate(this.model.readinessDate.getDate() + 1);
      this.model.totalVolume = Number(String(this.model.totalVolume).replace(",","."));

      // daha önce bulunan ürün varsa siliniyor.
      this.model.products = [];
      this.model.id = null;

      // Eğer mal bedeli 500.000 USD den büyük ise fiyat talep et çıkması için arama yaptırmıyoruz.
      localStorage.setItem('search', JSON.stringify(this.model))
      if(this.model.goodPrice < 500000) {
        this.model = await this.airSearchService.search(this.model).toPromise();
      }

      this.parent.productLoading = false;
      window.scroll({ top: this.parent.initialOffsetTop,  left: 0, behavior: 'smooth' });
      this.parent.container.clear();

      this.parent.model = this.model;

      this.stepperService.changeCurrentStep(2);
      if(!this.parent.showOnlyComponent) {
        this.addQueryParam("page", "FYT")
        this.addQueryParam("op", "AIR")
        this.addQueryParam("type", `${this.model.departurePort.name.slice(0,3)}(${this.model.departureCountry.code})-(${this.model.arrivalCountry.code})${this.model.arrivalPort.name.slice(0,3)}`)
      }



    } catch (error) {
        // if (error == 'userBannedForManyRequest') {
        //     this.forceLogOut();
        // }
        this.parent.productLoading = false;
    }

  }

  async getSegment(portFieldName = null) {
    if((this.model.arrivalPort && this.model.departurePort) && this.model.arrivalPort.id == this.model.departurePort.id) {
      setTimeout(() => this.model[portFieldName] = null, 100);
      this.showMessage('warn', 'samePortWarn')

    }
    if(this.model.arrivalPort && this.model.departurePort) {
      const segment = await this.airSearchService.segment(this.model.departurePort.id, this.model.arrivalPort.id).toPromise();
      this.model.segment = segment;
    }
  }
     forceLogOut(){
        Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))
        setTimeout(() => {
          this.router.navigate(['/home']);
         }, 4000);
    }

  onChangeService() {

    if(!this.model.service || (this.model.service && this.model.service == 'DTD')) return;

    if(this.model.service[0] == 'D') {
      this.model.arrivalAddress = null;
      this.model.arrivalZip = null;
    }

    if(this.model.service[this.model.service.length -1] == 'D') {
      this.model.departureAddress = null;
      this.model.departureZip = null;
    }


  }

  onChangeTD(td: string) {
    this.model.td = td;
    this.model.packs = [];
    this.model.packingType = null;
    this.model.calculatedPieces = 0;
    this.model.pieces = 0;

    this.model.totalVolume = null;
    this.model.cwg = 0;
    this.model.totalWeight = null;

    if(td == 'D') this.addPack();
  }

  onChangeDepartureCountry() {
    this.model.departurePort = null;
    this.model.departureZip = null;
    this.model.departureCity = null;
  }
  onChangeArrivalCountry() {
    this.model.arrivalPort = null;
    this.model.arrivalZip = null;
    this.model.arrivalCity = null;

  }

    onSelectZipToAddress(e: any, elementRef: any, zipField = 'departureZip' ){
        if (zipField == 'departureZip') this.model.departureAddress = e.name;
        if (zipField == 'arrivalZip') this.model.arrivalAddress = e.name;
        this.onSelectZip(e, elementRef, zipField);
    }

  calculateTotals(event:any = null) {
    if(this.model.td == 'T') {
      const calculatedCwg = (Number(String( event?.value || this.model.totalVolume).replace(',', '.')) * this.cwValue);
      this.model.cwg =  Number(Number(this.decimalPipe.transform(calculatedCwg > this.model.totalWeight ? calculatedCwg : this.model.totalWeight)).toFixed())
    }
    if(this.model.td == 'D') {

      //calculate total pieces
      this.model.calculatedPieces = 0;
      const pieces = this.model.packs.filter(f => f.pieces).map(f => f.pieces);
      if(pieces.length > 0)  this.model.calculatedPieces = pieces.reduce((a,b) => a + b);

      // calculate total weight
      this.model.totalWeight = 0;
      this.model.packs.forEach(p => {
        this.model.totalWeight +=  (p.pieces * (p.perKg | 0));
      })


      // calculate line volume & calculate line total weight
      this.model.packs.forEach(i => {
        i.lineVolume =  (((i.height | 0) * (i.lenght | 0) * (i.width | 0)) / 1000000) * (i.pieces | 0);
        i.totalWeight = (i.perKg | 0) * (i.pieces | 0);
      })


      // calculate total volume
      this.model.totalVolume = 0;
      const volumes = this.model.packs.filter(f => f.lineVolume).map(f => f.lineVolume);
      if(volumes.length > 0)  this.model.totalVolume = volumes.reduce((a,b) => a + b);


      // calculate cwg
      this.model.cwg = 0;
      const cwg =  ((Number(String(this.model.totalVolume).replace(',', '.')) || 0) * this.cwValue) > this.model.totalWeight ? ((this.model.totalVolume || 0) * this.cwValue) : this.model.totalWeight;
      this.model.cwg = Number(Number(this.decimalPipe.transform(cwg)).toFixed());
    }
  }

  showUseTrms() {
    this.termsDialog = this.dialogService.open(DocViewerDialogComponent, { header: "view", showHeader: false, modal:true, closable: true,  width: '50%', height: '100%',  data: {filePath: `/assets/data/documents/terms_${this.translation.currentLang}.html`}});

}
    deletePortByField(event: any ,field: string) {
        setTimeout(()=> {
            if (!event?.id){
                this.model[field] = null;
            }
        }, 100);
    }

}
