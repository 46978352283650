import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from "rxjs";
import { ChangeLanguageService } from "./services/change-language.service";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styles:[
        `::ng-deep .p-menubar.p-component{
            border: none;
        }
        ::ng-deep .p-menuitem-text{
            font-style: normal;
            line-height: 19px;
            color: #414141 !important;
            font-size: 12px;
            font-weight: 700 !important;
        }
        ::ng-deep .p-menuitem-link:focus{
            box-shadow: none !important;
        }
        ::ng-deep .p-menuitem-icon {
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            color: #414141 !important;
        }
        ::ng-deep .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
            color: #005CBA !important;
        }
        ::ng-deep .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text:hover{
            color: #005CBA !important;
        }
        ::ng-deep .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text:active{
            color: #005CBA !important;
        }
        ::ng-deep a.p-menuitem-link.p-ripple.ng-star-inserted.p-menuitem-link-active{
            color: #005CBA;
        }
        ::ng-deep .p-button:focus {
            box-shadow:none !important;
        }
        `
    ]
})
export class AppMenuComponent implements OnInit, OnDestroy {

    model: any[];
    languageChanged: Subscription;

    menuItems: any[] = [];




    itemsWithLanguages: any  =  {
        'en':
            [
                {
                    label: 'HOME',
                    routerLink: ['/home'],
                    icon: 'pi pi-home',
                },
                {
                    label: 'QUOTATIONS',
                    icon: 'pi pi-fw pi-dollar',
                    items: [
                        {label: 'AIR FREIGHT QUOTE',
                            routerLink: ['/home/air'],
                        items: [
                            {
                                label: 'NEW QUOTE',
                                routerLink: ['/home/air']},
                            {
                                label: 'MY QUOTATIONS',
                                routerLink: ['/home/air/list/notReservedList' ],
                                queryParams: {'operation': 'air'}
                            },
                        ]
                    },
                        {label: 'SEA FREIGHT QUOTE',
                        items: [
                            {
                                label: 'NEW QUOTE',
                                routerLink: ['/home/sea']
                            },
                            {
                                label: 'MY QUOTATIONS',
                                routerLink: ['/home/air/list/notReservedList'],
                                queryParams: {'operation': 'sea'}
                            },
                        ]},
                        {label: 'ROAD FREIGHT QUOTE',
                        items: [
                            {label: 'NEW QUOTE'},
                            {
                                label: 'MY QUOTATIONS',
                                routerLink: ['/home/air/list/notReservedList'],
                                queryParams: {'operation': 'truck'}
                            },
                        ]}
                    ]
                },
                {
                    label: 'MY BOOKING REQUESTS',
                    icon: 'pi pi-fw pi-calendar-minus',
                    items: [
                        {label: 'AIR FREIGHT',
                        items: [
                            {
                                label: 'MY BOOKING REQUESTS',
                                routerLink: ['/home/air/list/reservation'],
                                queryParams: {'operation': 'air'}
                        },
                        ]},
                        {label: 'SEA FREIGHT',
                        items: [
                            {
                                label: 'MY BOOKING REQUESTS',
                                routerLink: ['/home/air/list/reservation'],
                                queryParams: {'operation': 'sea'}
                            },
                        ]},
                        {label: 'ROAD FREIGHT',
                        items: [
                            {
                                label: 'MY BOOKING REQUESTS',
                                routerLink: ['/home/air/list/reservation'],
                                queryParams: {'operation': 'truck'}
                            },
                        ]}
                    ]
                },
                {
                    label: 'SHIPMENTS',
                    icon: 'pi pi-fw pi-dollar',
                    items: [
                        {
                            label: 'COMPLETED',
                            routerLink: ['/home/air/list/completedOperations'],
                            queryParams: {'operation': 'air'}
                        },
                        {
                            label: 'ONGOING',
                            routerLink: ['/home/air/list/inProgressOperation'],
                            queryParams: {'operation': 'air'}
                        }
                    ]
                }
            ],
        'tr':
            [
                {
                    label: 'ANA SAYFA',
                    routerLink: ['/home'],
                    icon: 'pi pi-home',
                },
                {
                    label: 'FİYAT TEKLİFİ',
                    icon: 'pi pi-fw pi-dollar',
                    items: [
                        {label: 'HAVA TEKLİF',
                            routerLink: ['/home/air'],
                        items: [
                            {
                                label: 'YENİ TEKLİF',
                                routerLink: ['/home/air'],},
                            {
                                label: 'TEKLİFLERİM',
                                routerLink: ['/home/air/list/notReservedList' ],
                                queryParams: {'operation': 'air'}
                            },
                        ]
                    },
                        {label: 'DENİZ TEKLİF',
                            routerLink: ['/home/sea'],
                        items: [
                            {
                                label: 'YENİ TEKLİF',
                                routerLink: ['/home/sea']
                            },
                            {
                                label: 'TEKLİFLERİM',
                                routerLink: ['/home/air/list/notReservedList'],
                                queryParams: {'operation': 'sea'}
                            },
                        ]},
                        {label: 'KARAYOLU TEKLİF',
                        items: [
                            {label: 'YENİ TEKLİF'},
                            {
                                label: 'TEKLİFLERİM',
                                routerLink: ['/home/air/list/notReservedList'],
                                queryParams: {'operation': 'truck'}},
                        ]}
                    ]
                },
                {
                    label: 'REZERVASYON TALEPLERİM',
                    icon: 'pi pi-fw pi-calendar-minus',
                    items: [
                        {label: 'HAVA',
                            routerLink: ['/home/air/list/reservation'],
                            queryParams: {'operation': 'air'},
                        items: [
                            {
                                label: 'REZERVASYONLARIM',
                                routerLink: ['/home/air/list/reservation'],
                                queryParams: {'operation': 'air'}
                            },
                        ]},
                        {label: 'DENİZ',
                            routerLink: ['/home/air/list/reservation'],
                            queryParams: {'operation': 'sea'},
                        items: [
                            {
                                label: 'REZERVASYONLARIM',
                                routerLink: ['/home/air/list/reservation'],
                                queryParams: {'operation': 'sea'}
                            },

                        ]},
                        {label: 'KARAYOLU',
                        items: [
                            {
                                label: 'REZERVASYONLARIM',
                                routerLink: ['/home/air/list/reservation'],
                                queryParams: {'operation': 'truck'}
                            },
                        ]}
                    ]
                },
                {
                    label: 'YÜKLEMELER',
                    icon: 'pi pi-fw pi-table',
                    items: [
                        {
                            label: 'TAMAMLANAN',
                            routerLink: ['/home/air/list/completedOperations'],
                            queryParams: {'operation': 'air'}
                        },
                        {
                            label: 'DEVAM EDEN',
                            routerLink: ['/home/air/list/inProgressOperation'],
                            queryParams: {'operation': 'air'}
                        }
                    ]
                }
            ]
    };

    items: any[] = null;
    constructor(public appMain: AppMainComponent, private t: TranslateService, private changeLanguageService: ChangeLanguageService) {
        this.t.use(localStorage.getItem('lang') || 'tr');
    }

    ngOnInit() {

        this.languageChanged = this.changeLanguageService.languageChanged.subscribe((lang) => {
            this.items = this.itemsWithLanguages[this.changeLanguageService.getCurrentLanguage()];
        });

        this.items = this.itemsWithLanguages[this.changeLanguageService.getCurrentLanguage()];

    }

    onMenuClick() {
        this.appMain.menuClick = true;
    }




    ngOnDestroy() {
        this.languageChanged.unsubscribe();
    }


}
