import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
  styles:[`
        footer {
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: 100%;

            padding: 9px;
            z-index: 9999;
           }
        `
    ],
    encapsulation:ViewEncapsulation.None
})
export class AppFooterComponent {
    firm: any;

    constructor(public translation: TranslateService) {

    }

    ngOnInit(): void {
        this.firm = sessionStorage.getItem('firm');
    }
}
