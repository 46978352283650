import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class ChangeLanguageService {
    languageChanged = new Subject<string>();

    constructor(private translateService: TranslateService) {
    }

    getCurrentLanguage() {
        return localStorage.getItem('lang') || 'en';
    }
}
