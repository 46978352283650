import { Injectable } from "@angular/core";
import { fakeAsync } from "@angular/core/testing";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        var authToken = localStorage.getItem("token");
        if (authToken) {
            return true;
        } else {
            Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))
            this._router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
