import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FixedPriceService} from "../../services/fixedPrice/fixed-price.service";

@Component({
  selector: 'app-landpage-search-price',
  templateUrl: './landpage-search-price.component.html',
  styleUrls: ['./landpage-search-price.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandpageSearchPriceComponent implements OnInit {
    selectedValue: any;
    bannerPrices: any[];
    @Output() fixedPrices: EventEmitter<any[]> = new EventEmitter();
    tonnage: any;
    wm: any;
    weight: any;
    chargeableWeight: any;
    volume: any;
    containerType: any;
    truckType: any;
    constructor(private fixedPriceService: FixedPriceService) {
    }
   async ngOnInit() {
       const ipData = await this.fixedPriceService.getIpLocation(location.href.includes('https'));

       const items = await this.fixedPriceService.searchPricesByCode({code: ipData.countryCode}).toPromise()
       this.bannerPrices = items;
    }

    showPrices() {
        if (this.selectedValue) {
            history.replaceState(null,null,'')
            this.addQueryParam('operationType', this.selectedValue.operationType);
            if (this.selectedValue?.cwg) this.addQueryParam('cwg', this.selectedValue?.cwg);
            if (this.selectedValue?.partialFull) this.addQueryParam('partialFull', this.selectedValue?.partialFull);
            if (this.selectedValue?.containerType) this.addQueryParam('containerType', this.selectedValue?.containerType);
            if (this.selectedValue?.wm) this.addQueryParam('wm', this.selectedValue?.wm);
            if (this.selectedValue?.grossWeight) this.addQueryParam('grossWeight', this.selectedValue?.grossWeight);
            this.addQueryParam('departure', this.selectedValue?.fixedSearchDetList[0]?.departureCode);
            this.addQueryParam('arrival', this.selectedValue?.fixedSearchDetList[0]?.arrivalCode);
            this.fixedPrices.emit(this.selectedValue.fixedSearchDetList);
        }
    }

    setValues() {
        if (!this.selectedValue) {
            this.fixedPrices.emit(null);
        }
    }

    addQueryParam(key, value) {

        let queryParams = new URLSearchParams(window.location.search);
        queryParams.set(key, value);
        // history.replaceState(null, null, location.href + "?" + decodeURIComponent(queryParams.toString()));
        history.replaceState(null, null, location.pathname + "?" + decodeURIComponent(queryParams.toString()));
    }

    protected readonly screen = screen;
}
