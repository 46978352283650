
import { GenericEntity } from './generic-entity.model';
import {SimpleEntity} from './simple-entity.model';

export abstract class AbstractEntity implements GenericEntity {

    constructor(public id?: number, public firm?: SimpleEntity, public version?: number, public createDate?: Date, public copyId?: number) {

    }

}
