import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import {Subject} from "rxjs";
import { ViewEncapsulation } from '@angular/compiler';

export class acceptSubType{ 
  result: boolean = false;
  name: string = null;

  constructor(result, name) {
    this.result = result;
    this.name = name;
  }
}
export const docAcceptSub = new Subject<any>();

@Component({
  selector: 'app-doc-viewer-dialog',
  templateUrl: './doc-viewer-dialog.component.html',
  styleUrls: ['./doc-viewer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class DocViewerDialogComponent implements OnInit {


  loading: boolean = false;
  filePath: any;
  htmlContent: any;
  name: string = null;
  showButton: boolean = true;

  endOfScroll: boolean = false;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private httpClient: HttpClient) {
    this.filePath = this.config.data.filePath;
    this.name = this.config.data.name;
    this.showButton = this.config.data.showButton == false ? false : true;
  }

  async ngOnInit() {

    this.loading = true;

    this.htmlContent = await this.httpClient.get(this.filePath, {responseType: 'text'}).toPromise();
    this.loading = false;
  }

  closeDialog() {
    this.ref.close();
  }

  accept(selected: boolean) {
    
    docAcceptSub.next(new acceptSubType(selected, this.name))
    this.closeDialog();
  }


}
