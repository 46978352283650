import {MinDTO} from "../min-dto";

export class SeaPackModel {
    lenght: number;
    width: number;
    height: number;
    perKg: number;
    lineVolume: number;
    pieces: number;
    packing: string;
    totalWeight: number;
}
