import { Component, OnInit, Injector, Inject } from '@angular/core';
import { StepperService } from 'src/app/stepper.service';
import { TruckComponent } from '../truck.component';
import { TruckSearchService } from 'src/app/services/truck/truck-search.service';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { TruckPriceDetailDialogComponent } from 'src/app/components/truck-price-detail-dialog/truck-price-detail-dialog.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import {Router} from "@angular/router";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class TruckConfirmationComponent  extends BaseFormComponentComponent implements OnInit {

  currentStep: number;
  priceInfoDialogRef: DynamicDialogRef;

  constructor(
    protected injector: Injector, @Inject(TruckComponent) private parent: TruckComponent, private stepperService: StepperService, private truckSearchService: TruckSearchService, private router: Router,) {
    super(injector)
    this.parent.isLoading = true;

  }
  async showTruckPriceInfo() {
    this.priceInfoDialogRef = this.dialogService.open(TruckPriceDetailDialogComponent, { header: null, showHeader: false, modal:true,  width: '800px', height: 'auto', closable: false,  data: {product: this.parent.model.selectedProduct, model: this.parent.model}});
  }
  ngOnInit(): void { }

    goList() {
        this.router.navigate(['/truck/list/reservation'], { queryParams: { operation: 'truck'} });
    }

}
