import { Component, OnInit, Inject } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AirModel } from 'src/app/models/air/air-model';
import { MarketAirProductsModel } from 'src/app/models/air/market-air-products.model';
import { SurcharDetailDialog } from '../surcharge-detail-dialog/surcharge-detail-dialog.component';
import { AirCalculatedChargesModel } from 'src/app/models/air/air-calculated-charges.model';
import { ClickHistorySubject } from 'src/app/services/search-log-click-history.service';
import { SearchLogClickHistoryModel } from 'src/app/models/search-log-click-history.model';
import { FrontEndTariffModel } from 'src/app/models/front-end-tariff.model';
import { ChangeLanguageService } from 'src/app/services/change-language.service';
import { AirComponent } from 'src/app/pages/air/air.component';
import { AirSearchService } from 'src/app/services/air/air-search.service';

@Component({
  selector: 'app-air-price-detail-dialog',
  templateUrl: './air-price-detail-dialog.component.html',
  styleUrls: ['./air-price-detail-dialog.component.scss']
})
export class AirPriceDetailDialogComponent implements OnInit {

  product: MarketAirProductsModel = null;
  model: AirModel = null;
  surchargeDialogRef: DynamicDialogRef;
  lang: string = "tr";


  constructor(public config: DynamicDialogConfig, @Inject(AirComponent) public parent: AirComponent, private dialogService: DialogService, private airService: AirSearchService, public ref: DynamicDialogRef, private languageService: ChangeLanguageService) {
    this.product = this.config.data.product;
    this.model = this.config.data.model;
    this.lang =  this.languageService.getCurrentLanguage();


  }

  ngOnInit(): void {
    this.createClickHistory();
  }

  async showSurchages(surcharge: AirCalculatedChargesModel) {
    this.surchargeDialogRef = this.dialogService.open(SurcharDetailDialog, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { surcharge: surcharge } });

    this.createClickHistory(surcharge?.name);
  }

  async showFrontEndSurchages(surcharge: FrontEndTariffModel) {
    this.surchargeDialogRef = this.dialogService.open(SurcharDetailDialog, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { surcharge: surcharge } });

    this.createClickHistory(surcharge?.name);
  }

  closeDialog() {
    this.ref.close();
  }

  createClickHistory(costType = 'PD') {
    const history = new SearchLogClickHistoryModel();
    history.productId = this.product?.id;
    history.productName = this.product?.name
    history.searchLogId = this.model?.id;
    history.costType = costType;
    ClickHistorySubject.next(history);

  }

   async onApproveDue(product: MarketAirProductsModel) {
    this.product = product;
    const airModel = await this.airService.insurance(this.model).toPromise();
    this.model.insurance = airModel.insurance;
    this.parent.showMessage('success', product?.selectedDue ? 'approvedDueDay' : 'approvedCash');
  }
}
