import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import { NotificationsModel } from "src/app/models/notifications.model";

@Injectable()
export class NotificationsService extends BaseService<any>{
  constructor(public httpClient: HttpClient) {
    super(
      'notifications',
      httpClient);
  }

    getAllNotifications(): Observable<NotificationsModel[]> {
        let uri = new URL(environment.baseUrl + `/notifications`)

        return this.httpClient.get<NotificationsModel[]>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    
    getWithOffsetAndReadTime(isNew: boolean, offset:string, ids:number[] = []): Observable<NotificationsModel[]> {
      let uri = new URL(environment.baseUrl + `/notifications/getWithOffsetAndReadTime`).updateQueryParam({isNew: isNew || "false", offset: offset, ids:  ids.join(',')});
      return this.httpClient.get<NotificationsModel[]>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
          .pipe(
              tap(),
              catchError(this.handleError)
          )
  }

     
  readNotifications(ids:number[] = []): Observable<NotificationsModel[]> {
    let uri = new URL(environment.baseUrl + `/notifications/readNotifications`).updateQueryParam({ids:  ids.join(',')})
    
    return this.httpClient.get<NotificationsModel[]>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
}

}
