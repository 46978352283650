import {SimpleEntity} from "../simple-entity.model";
import { UserDocumentDTO } from "./user-document.model";

export class DialCodeDTO {
    name: string;
    dial_code: string
    emoji: string
    code: string
}
export class UserModel {
    companyName: string;
    title: string;
    password: string;
    passwordReply: string
    referenceNo: string;
    name: string;
    surname: string;
    mobile: string;
    companyLineNumber: string;
    email: string;
    ipRestriction: string;
    status: string;
    locale: string;
    verificationCode: number;
    taxNo: string;
    identifyNo: string;
    dialCode: DialCodeDTO = {"name": "Turkey","dial_code": "+90","emoji": "🇹🇷","code": "TR"};
    isTwoFactor: boolean;
    isKvkk: boolean;
    isMembershipAgreement;
    isSms: boolean;
    isMail: boolean;
    isAgency: boolean;
    kvkkDate: Date;
    isAgent: boolean;
    countryCode: string;
    dialPhoneCode: string;
    dialCountryCode: string;
    dialCountryName: string;
    company: SimpleEntity = new SimpleEntity();
    companyPerson: SimpleEntity = new SimpleEntity();
    country: SimpleEntity = new SimpleEntity();
    user: SimpleEntity = new SimpleEntity();

    tradeRegistryGazette: UserDocumentDTO = null;
    signatureCirculars: UserDocumentDTO = null;
    invoiceInfo: UserDocumentDTO = null;
    companyRegisterationForm: UserDocumentDTO = null;
    agentNetworkCertificate: UserDocumentDTO = null;
}
