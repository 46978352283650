import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import './shared/prototypes';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {DynamicDialogModule} from 'primeng/dynamicdialog';


import { AppPublicComponent } from './app-public.component';
import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppRightpanelComponent } from './app.rightpanel.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { GridComponent } from './utilities/grid.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';


import { MenuService } from './app.menu.service';


import { TokenInterceptor } from './services/token-interceptor.service';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { UserService } from './services/user/user.service';
import { DialogService } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';


import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrderByPipe } from './utilities/pipes/orderby-pipe';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

// modals
import { AdminDialogComponent } from './components/admin-dialog/admin-dialog.component';
import { AirPriceDetailDialogComponent } from './components/air-price-detail-dialog/air-price-detail-dialog.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { DocViewerDialogComponent } from './components/doc-viewer-dialog/doc-viewer-dialog.component';
import { SearchDetailDialog } from './components/search-detail-dialog/search-detail-dialog.component';
import { ActivationDialogComponent } from './components/activation-dialog/activation-dialog.component';
import { SurcharDetailDialog } from './components/surcharge-detail-dialog/surcharge-detail-dialog.component';

// shared components
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { EmailAutocompleteComponent } from './shared/email-autocomplete/email-autocomplete.component';
import { SelectBoxComponent } from './shared/select-box/select-box.component';
import { AutocompleteComponent } from './shared/autocomplete/autocomplete.component';
import { SelectBoxItemPipe } from './shared/select-box-item-pipe/select-box-item.pipe';
import { UseUtcDirective } from './shared/p-calender-utc';
import { LocaleDatePipe } from './shared/locale-date-pipe/locale-date.pipe';
import { SumPipe } from './shared/sum-pipe/sum.pipe';
import { DecimalPipe } from './shared/decimal.pipe';
import { DocViewerBtnComponent } from './components/doc-viewer-btn/doc-viewer-btn.component';
import { CountDownComponent } from './components/countdown/countdown.component';
import { ToastComponent } from './framework/toast/toast.component';
import { DueComponentComponent } from './components/due-component/due-component.component';

// services
import { AuthGuard } from './services/auth-guard';
import { AuthenticationService } from './services/user/authentication.service';

import { AirportService } from './services/air/airport.service';
import { SeaportService } from './services/sea/seaport.service';
import { CompanyService} from './services/company/company.service';
import { CountryService } from './services/country/country.service';
import { ZipService } from './services/zip/zip.service';
import { TaxOfficeService } from './services/taxOffice/taxOffice.service';
import { CityService } from './services/city/city.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { SelectBoxItemService } from './services/select-box-item.service';
import { ContainerTypeService } from './services/container-type/container-type.service';
import { ListService } from './services/list/list.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { SearchLogClickHistoryService } from './services/search-log-click-history.service';

// page components
import { AppLoginComponent } from './pages/app.login.component';
import { ChangePasswordComponent } from './pages/user/change-password/change-password.component';
import { TooltipModule } from 'primeng/tooltip';

import { PStepsComponent } from './framework/p-steps/p-steps.component';

import { AirComponent } from './pages/air/air.component';
import { AirPriceRequestComponent } from './pages/air/price-request/price-request.component';
import { AirReservationInformationComponent } from './pages/air/reservation-information/reservation-information.component';
import { AirConfirmationComponent } from './pages/air/confirmation/confirmation.component';
import { NotificationsComponent } from './pages/user/notifications/notifications.component';
import { AirSearchComponent } from './pages/air/air-search/air-search.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { ForgotPasswordComponent } from './pages/user/forgot-password/forgot-password.component';
import { SeaComponent } from './pages/sea/sea.component';
import { SeaPriceRequestComponent } from './pages/sea/price-request/price-request.component';
import { SeaReservationInformationComponent } from './pages/sea/reservation-information/reservation-information.component';
import { SeaConfirmationComponent } from './pages/sea/confirmation/confirmation.component';
import { SeaSearchComponent } from './pages/sea/search/search.component';

import { AppRegisterComponent } from './pages/app.register.component';
import { SelectListComponent } from './framework/select-list/select-list.component';
import { DatepickerComponent } from './framework/datepicker/datepicker.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListComponent } from './pages/list/list.component';
import { VerifyComponent } from './pages/verify.component';
import { SeaPriceDetailDialogComponent } from './components/sea-price-detail-dialog/sea-price-detail-dialog.component';
import { TariffNotFoundDialogComponent } from './components/tariff-not-found-dialog/tariff-not-found-dialog.component';
import {
    TruckPriceDetailDialogComponent
} from './components/truck-price-detail-dialog/truck-price-detail-dialog.component';
import {TruckComponent} from './pages/truck/truck.component';
import {TruckSearchComponent} from './pages/truck/truck-search/truck-search.component';
import {
    TruckReservationInformationComponent
} from './pages/truck/reservation-information/reservation-information.component';
import {TruckPriceRequestComponent} from './pages/truck/price-request/price-request.component';
import {TruckConfirmationComponent} from './pages/truck/confirmation/confirmation.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LandPagePricesCarrouselComponent } from './components/land-page-prices-carrousel/land-page-prices-carrousel.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CustomersReviewCarouselComponent } from './components/customers-review-carousel/customers-review-carousel.component';
import { WopStepperCarrouselComponent } from './components/wop-stepper-carrousel/wop-stepper-carrousel.component';
import {FixedPriceService} from "./services/fixedPrice/fixed-price.service";
import { LandpageLoginComponent } from './components/landpage-login/landpage-login.component';
import { LandpageAccordionFaqComponent } from './components/landpage-accordion-faq/landpage-accordion-faq.component';
import { LandpageCarouselVideoComponent } from './components/landpage-carousel-video/landpage-carousel-video.component';
import { LandpageSearchPriceComponent } from './components/landpage-search-price/landpage-search-price.component';
import { DashboardFixedPricesComponent } from './components/dashboard-fixed-prices/dashboard-fixed-prices.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
export function playerFactory() {
    return player;
}


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SkeletonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        EditorModule,
        HttpClientModule,
        NgxDocViewerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        MatToolbarModule,
        MatIconModule,
        MatGridListModule,
        MatMenuModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatListModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        DragDropModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SkeletonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        ProgressSpinnerModule,
        MatStepperModule,
        DynamicDialogModule,
        NgxMaskModule.forRoot(),
        LottieModule.forRoot({ player: playerFactory })
    ],
    declarations: [
        AppComponent,
        DashboardComponent,
        AppMainComponent,
        LoadingSpinnerComponent,
        AppPublicComponent,
        AppRightpanelComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DisplayComponent,
        ElevationComponent,
        GridComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppTimelineDemoComponent,
        AppAccessdeniedComponent,
        EmailAutocompleteComponent,
        ChangePasswordComponent,
        SelectBoxComponent,
        ForgotPasswordComponent,
        DocViewerDialogComponent,
        SearchDetailDialog,
        ActivationDialogComponent,
        SurcharDetailDialog,
        DocViewerBtnComponent,
        AutocompleteComponent,
        PStepsComponent,
        AppRegisterComponent,
        SelectListComponent,
        DatepickerComponent,
        AdminDialogComponent,
        ForgotPasswordDialogComponent,
        AirPriceDetailDialogComponent,
        SelectBoxItemPipe,
        LocaleDatePipe,
        SumPipe,
        DecimalPipe,
        UseUtcDirective,
        VerifyComponent,
        ListComponent,
        AirComponent,
        ProfileComponent,
        AirSearchComponent,
        AirReservationInformationComponent,
        AirPriceRequestComponent,
        AirConfirmationComponent,
        NotificationsComponent,
        SeaComponent,
        SeaSearchComponent,
        SeaPriceRequestComponent,
        SeaReservationInformationComponent,
        SeaConfirmationComponent,
        SeaPriceDetailDialogComponent,
        CountDownComponent,
        TariffNotFoundDialogComponent,
        ToastComponent,
        TruckComponent,
        TruckSearchComponent,
        TruckPriceDetailDialogComponent,
        TruckReservationInformationComponent,
        TruckPriceRequestComponent,
        TruckConfirmationComponent,
        LandingPageComponent,
        LandPagePricesCarrouselComponent,
        CustomersReviewCarouselComponent,
        WopStepperCarrouselComponent,
        LandpageLoginComponent,
        LandpageLoginComponent,
        LandpageAccordionFaqComponent,
        LandpageCarouselVideoComponent,
        LandpageSearchPriceComponent,
        DashboardFixedPricesComponent,
        DueComponentComponent
    ],
    providers: [
        MenuService, AppMainComponent, AppPublicComponent, DatePipe, OrderByPipe, AirComponent, AppComponent,
        TranslateService,
        TranslateStore,
        AuthenticationService,
        MessageService,
        ConfirmationService,
        UserService,
        AirportService,
        SeaportService,
        CountryService,
        ZipService,
        TaxOfficeService,
        CityService,
        CompanyService,
        ListService,
        NotificationsService,
        FixedPriceService,
        SearchLogClickHistoryService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        DialogService,
        ErrorHandlerService,
        SelectBoxItemService,
        SelectBoxItemPipe,
        ContainerTypeService
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
