export {};

declare global {
    interface URL {
        updateQueryParam(params): URL
    }

}

if (!URL.prototype.updateQueryParam) {  
    URL.prototype.updateQueryParam = function (params): URL {
        Object.keys(params).forEach(key => {
            this.searchParams.delete(key);
            if(params[key]) this.searchParams.set(key, params[key]);
        })
        return this;
    }
}



declare global {
    interface Number {
        fixNumberPercent(): Number
    }

}

if (!Number.prototype.fixNumberPercent) {  
    Number.prototype.fixNumberPercent = function (): Number {
       let value = String(this);
       let valueNumb = parseFloat(value.replace(",", ''));
       return Math.round((Number(valueNumb) + Number.EPSILON) * 100) / 100;
    }
}
