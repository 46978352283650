import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { ChangeLanguageService } from 'src/app/services/change-language.service';

@Component({
  selector: 'app-wop-stepper-carrousel',
  templateUrl: './wop-stepper-carrousel.component.html',
  styleUrls: ['./wop-stepper-carrousel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WopStepperCarrouselComponent implements OnInit, OnDestroy {

    langugeSubscription: Subscription;

    constructor(private translateService: TranslateService,  private languageService: ChangeLanguageService) {
    }
    index = 0;
    items: any[] = [
        {text: 'wopStepper', header: 'wopStepperHeader', image: 'login-mobile.png'},
        {text: 'searchPriceStepper', header: 'searchPriceStepperHeader', image: 'shipment-details.png'},
        {text: 'selectPriceStepper', header: 'selectPriceStepperHeader', image: 'select-offer-1.png'},
        {text: 'reservationStepper', header: 'reservationStepperHeader', image: 'booking-request.png'},
        {text: 'operationStepper', header: 'operationStepperHeader', image: 'check-details.png'},

    ];
    steps: any[] = [
        {
            label: 'WOP',
            labelKey: 'WOP',
            command: () => this.index = 0,
            disable: false
        },
        {   label: 'Fiyat Ara',
            labelKey: 'searchPriceStepperHeader',
            command: () => this.index = 1,
            disable: false},
        {
            label: 'Fiyat Seç',
            labelKey: 'selectPriceStepperHeader',
            command: () => this.index = 2,
            disable: false
        }, {
            label: 'Rezervasyon Yap',
            labelKey: 'reservationStepperHeader',
            command: () => this.index = 3,
            disable: false
        }, {
            label: 'Operasyon Takip',
            labelKey: 'operationStepperHeader',
            command: () => this.index = 4,
            disable: false
        }];
    indexStep: any;


    onPage(event: any) {
        this.index = event.page;
    }


    async getLabelTranslate(){

        let stp = this.steps;

        for (let i = 0; i < this.steps.length ; i++) {
            stp[i].label = await this.translateService.get(this.steps[i].labelKey).toPromise();
        }

        this.steps = stp;
    }

   async ngOnInit() {
    await this.getLabelTranslate();

    this.langugeSubscription = this.languageService.languageChanged.subscribe(lang => {
        // yeniden renderlaması için index'in değişmesi gerekiyor o yüzden aşağıdaki gibi bir işlem yaptık şimdilik..
        this.getLabelTranslate().then(() => {
            if(this.index  == 0) {
                this.index = 1;
                setTimeout(() =>  this.index = 0, 500);
            } else {
                this.index = 0;
            }
        })
    })}

    ngOnDestroy(): void {
        this.langugeSubscription.unsubscribe();
    }

}
