import { Component, OnDestroy, OnInit,  ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { Subscription } from 'rxjs';
import { ChangePasswordComponent } from './pages/user/change-password/change-password.component';
import { Router } from '@angular/router';
import { NotificationsComponent } from './pages/user/notifications/notifications.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { NotificationsModel } from './models/notifications.model';
import { NotificationsService } from './services/notifications/notifications.service';
import { readNotify } from './pages/user/notifications/notifications.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styles: [`
       ::ng-deep .layout-topbar-wrapper .p-badge-no-gutter {
        background: red !important;
       }

       
       `
    ]
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    notificationsdialogRef: DynamicDialogRef;
    notificationsCount: number;
    notificationsList: NotificationsModel[] = [];

    items: any[] = [];
    filteredItems: any[] = [];

    firm: any;


    constructor(public app: AppComponent, public appMain: AppMainComponent, private router: Router, public viewContainerRef: ViewContainerRef, 
        private componentFactoryResolver: ComponentFactoryResolver, private dialogService: DialogService, private notificationsService :NotificationsService) { }
    ngOnInit(): void {
        this.firm = sessionStorage.getItem('firm');


        const appComponent = this.router.config.find(f => f.component.name == 'AppMainComponent');
        if(appComponent) {
            const routes = appComponent.children.filter(f => f.data && f.data.useSearch);
            routes.forEach(r =>  this.items.push({ name: r.data.title, value: r.path}));
        }

        this.showNotificationsCount();
       
        readNotify.subscribe(count => {
            this.notificationsCount = (this.notificationsCount - count) >= 0 ? (this.notificationsCount - count) : 0;
        })
    }

    ngOnDestroy() {

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if(readNotify) readNotify.unsubscribe();
    }

    onSearch(event) {
        this.filteredItems = [];
        this.items.forEach(v => {
            if (v.name.toLocaleUpperCase('tr-TR').indexOf(event.query.toLocaleUpperCase('tr-TR')) === 0)  this.filteredItems.push(v);
        });
        return this.filteredItems;
    }

    onHandleSearchSelect(event) {
        this.router.navigate([event.value]);
    }

    showChangePasswordDialog() {
        let factory = this.componentFactoryResolver.resolveComponentFactory(ChangePasswordComponent);
        let componentRef = this.viewContainerRef.createComponent(factory);
        componentRef.instance.display = true;
      }

      showNotificationsDialog() {
        this.notificationsdialogRef = this.dialogService.open(NotificationsComponent,
         { header: null, showHeader: false, modal:true,  width: '800px', height: '800px', closable: false,  data: {}});
      }

      async showNotificationsCount() {
        this.notificationsList = await  this.notificationsService.getAllNotifications().toPromise();
        if(this.notificationsList && this.notificationsList.length > 0) {
            let count = this.notificationsList.filter(f => !f.readTime).length;
            this.notificationsCount = count;
        }
        
        
      }



}
