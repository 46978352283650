import { Inject, Injectable } from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { Base } from "../models/base.model";
import {getToken} from "./token-interceptor.service";
import { errorMessagerSubject$ } from "./error-handler.service";

@Injectable()
export class BaseService<T extends Base> {

    protected httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'q=0.8;application/json;q=0.9'
        }),
    };

    url = '';

    constructor(@Inject(String) protected endpoint: string, protected httpClient: HttpClient ) {
        this.url = environment.baseUrl;

        this.httpOptions.headers = this.httpHeaders();
        
    }

    protected httpHeaders(contentType = 'application/json' ): HttpHeaders {
        let headers = new HttpHeaders();
        headers.append("Accept", "application/json");
        headers.append('Content-Type', contentType);
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", 'true');
        headers.append("Access-Control-Allow-Methods", '*');
        headers.append("timeout", '10000');

        return headers;
    }

    createRequestOption = (req?: any): HttpParams => {
        let options: HttpParams = new HttpParams();
        if (req) {
            Object.keys(req).forEach((key) => {
                if (key !== 'sort') {
                    options = options.set(key, req[key]);
                }
            });
            if (req.sort) {
                req.sort.forEach((val) => {
                    options = options.append('sort', val);
                });
            }
        }
        return options;
    }

    getAll(name: string = "", transportDirection?: string): Observable<T[]> {

        var token = getToken();

        var url = environment.baseUrl + "/" + this.endpoint + "/getall";

        var option = this.createRequestOption({token: token.token, sessionId: token.sessionId, name, transportDirection})

        return this.httpClient.get<T[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: option})
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    protected handleError(error: any) {
        let errorMessage = '';
        try {
            if (error.error instanceof ErrorEvent) {
                //error client
                errorMessage = error.error.message;
            } else {
                //error server
                errorMessagerSubject$.next(error)
            }
        } catch (ex) {
            return throwError(error);
        }

        return throwError(error);
    }

    /** Log a service with the LogService
     * Not implemented yet!!
    */
    protected log(message: string) {
        // TODO: Logging implementation
    }
}
